import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeading,
} from '@/atoms/Dialog';
import React from 'react';
import styles from './LoginNudge.module.scss';
import Button from '@/atoms/Button';

interface ILoginNudgeProps {
  open: boolean;
  setNudgeOpen: (open: boolean) => void;
}

export const LoginNudge = ({ open, setNudgeOpen }: ILoginNudgeProps) => {
  return (
    <Dialog open={open} onOpenChange={setNudgeOpen}>
      <DialogContent className={styles.container}>
        <DialogHeading className={styles.heading}></DialogHeading>
        <DialogBody className={styles.content}>
          <h3 className="title">Søgeagent & favoritter</h3>
          <p className="body">
            Log ind for at gemme dine favoritannoncer og oprette søgeagenter.
          </p>
          <div className={styles.buttonsWrapper}>
            <Button as="a" href="/bruger/log-ind/" variant="Secondary">
              Opret bruger
            </Button>
            <Button as="a" href="/bruger/log-ind/" variant="Primary">
              Log ind
            </Button>
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export default LoginNudge;
