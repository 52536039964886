import cx from 'classnames';
import styles from './ErrorMessage.module.scss';

export const ErrorMessage = () => {
  return (
    <div className={cx('spanAllColumns', styles.error)}>
      <h3>Øv, der opstod desværre en fejl ved hentning af søgeresultaterne.</h3>
      <p>
        Prøv venligst igen senere eller kontakt kundesupport, hvis problemet
        fortsætter.
      </p>
    </div>
  );
};
