export const VideoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M4.5 7h8a2 2 0 0 1 2 2v8h-8a2 2 0 0 1-2-2V7ZM16.5 10.5v3L20 16V8l-3.5 2.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

VideoIcon.displayName = 'VideoIcon';
export default VideoIcon;
