import { SmdBasSrpModelsProperty } from '../../types/api';
import styles from './ListingDetails.module.scss';
import React from 'react';
import cx from 'classnames';

interface Props {
  details: Record<string, SmdBasSrpModelsProperty> | undefined;
  sortBy?: string | null;
}

const standardProperties = [
  'firstregistrationdate',
  'mileage',
  'electricmotorrange',
  'kml',
  'geartype',
  'fueltype',
];

export const ListingDetails = ({ details, sortBy }: Props) => {

  return (
    <ul className={styles.list}>
      {standardProperties.filter((propKey) => {
        if (details?.[propKey]?.displayTextShort) {
          return !(propKey === 'geartype' && !!details['fueltype'] && ['El', 'Plug-in', 'Hybrid'].includes(details['fueltype'].displayTextShort!));
        } return false;
      }).map((propKey) => {
        return (
          <li
            key={propKey}
            className={cx(styles.listItem, {
              [styles.highlighted]: propKey === sortBy,
            })}
          >
            {details?.[propKey].displayTextShort}
            {propKey === 'electricmotorrange' && ' rækkevidde'}
          </li>
        );
      })}
      {!!sortBy &&
          sortBy !== 'distance' &&
          !standardProperties.includes(sortBy) &&
          details?.[sortBy]?.displayTextShort && (
              <li className={cx(styles.listItem, styles.highlighted)}>
                {details?.[sortBy].displayTextShort}
              </li>
          )}
    </ul>
  );
};
