import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  SaveSearchResponse,
  fetchSaveSearchDetails,
  postSaveSearchDetails,
} from 'api/search';
import { SmdBasSrpModelsSearchRequest } from 'types/api';
import { trackPulseUIElementEvent } from '@smd/tracking';

type NotificationSettings = {
  emailNotification: boolean;
  pushNotification: boolean;
};

export const useSearchAgent = (searchRequest: SmdBasSrpModelsSearchRequest) => {
  const [serverResponse, setServerResponse] = useState<SaveSearchResponse>();
  const [saveSearchSuccess, setSaveSearchSuccess] = useState<boolean>(false);
  const [saveSearchName, setSaveSearchName] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);
  const [showNameError, setshowNameError] = useState<boolean>(false);
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      emailNotification: true,
      pushNotification: true,
    });

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNotificationSettings((prev) => ({ ...prev, [name]: checked }));
  };

  const handleNotificationTracking = () =>
    trackPulseUIElementEvent({
      elementName: 'save-search',
      eventDescription: Object.keys(notificationSettings)
        .map((key) => {
          return `${key}: ${
            notificationSettings[key as keyof NotificationSettings]
              ? 'Enabled'
              : 'Disabled'
          }`;
        })
        .join(', '),
    });

  const getSavedSearchName = React.useCallback(
    async (sr: SmdBasSrpModelsSearchRequest) => {
      try {
        const response = await fetchSaveSearchDetails(sr);
        setServerResponse(response);
        setSaveSearchName(response?.name);
        if (response) {
          setNotificationSettings({
            emailNotification: response.emailNotification,
            pushNotification: response.pushNotification,
          });
        }
      } catch (error: unknown) {
        console.error(
          'Failed to fetch saved searches',
          (error as Error).message
        );
      }
    },
    []
  );

  const postSaveSearch = async () => {
    if (!saveSearchName) {
      setshowNameError(true);
      setShowError(false);
      return;
    } else {
      setshowNameError(false);
    }

    try {
      await postSaveSearchDetails({
        name: saveSearchName,
        searchRequest: searchRequest,
        ...notificationSettings,
      });
      setSaveSearchSuccess(true);
      handleNotificationTracking();
    } catch (error: unknown) {
      console.error('Failed to post saved searches', (error as Error).message);
    }
  };

  return {
    serverResponse,
    saveSearchName,
    setSaveSearchName,
    showError,
    showNameError,
    handleCheckboxChange,
    getSavedSearchName,
    postSaveSearch,
    saveSearchSuccess,
    selectedFilters: searchRequest,
    ...notificationSettings,
  };
};
