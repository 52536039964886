import Button from '@/atoms/Button';
import {
  DialogActions,
  DialogBody,
  DialogClose,
  DialogHeading,
} from '@/atoms/Dialog';
import { Level, Toast } from '@/atoms/Toast';
import TextField from '@/atoms/TextField';
import { useSearchAgent } from '@/hooks/useSearchAgent';
import CheckmarkIcon from '@/icons/CheckmarkIcon';
import SearchingIllustration from 'components/illustrations/SearchingIllustration';
import React from 'react';
import { SmdBasSrpModelsSearchRequest } from 'types/api';
import styles from './SearchAgent.module.scss';
import cx from 'classnames';

interface Props {
  selectedFilters: SmdBasSrpModelsSearchRequest;
}

export const SearchAgentContent = ({ selectedFilters }: Props) => {
  const {
    serverResponse,
    saveSearchName,
    setSaveSearchName,
    // showError,
    showNameError,
    emailNotification,
    pushNotification,
    handleCheckboxChange,
    getSavedSearchName,
    postSaveSearch,
    saveSearchSuccess,
  } = useSearchAgent(selectedFilters);

  React.useEffect(() => {
    getSavedSearchName(selectedFilters);
  }, [getSavedSearchName, selectedFilters]);

  return (
    <>
      {!saveSearchSuccess && (
        <DialogHeading hideCloseIcon hideBackIcon={false}>
          Opret søgeagent
        </DialogHeading>
      )}
      <DialogBody className={styles.dialogBody}>
        <>
          {saveSearchSuccess ? (
            <div className={styles.searchagentCreatedContainer}>
              <CheckmarkIcon />
              <h3 className={cx('title', styles.title)}>
                Din søgeagent er oprettet
              </h3>
              {pushNotification || emailNotification ? (
                <p className={cx(styles.subtitle, 'subtitle')}>
                  Du får besked så snart der er et match
                </p>
              ) : null}
              <p className={styles.text}>
                Du kan altid tilpasse dine søgeresultater under din profil.
              </p>
              <DialogClose>
                <Button variant="Secondary" onClick={() => {}}>
                  Tilbage til søgning
                </Button>
              </DialogClose>
            </div>
          ) : (
            <>
              <div className={styles.searchAgentInfo}>
                <div className={styles.searchIllustrationWrapper}>
                  <SearchingIllustration />
                </div>
                <p>
                  Med en søgeagent får du besked før alle andre, så snart en bil
                  til salg matcher dine præferencer.
                </p>
              </div>
              <div className={styles.searchNameWrapper}>
                <h3 className={styles.title}>Navngiv søgeagenten</h3>
                <TextField
                  fullWidth
                  value={saveSearchName ?? ''}
                  onChange={(e) => setSaveSearchName(e.currentTarget.value)}
                  wrapperProps={{ className: styles.search }}
                />
              </div>
              {showNameError && (
                <Toast level={Level.ERROR} text="For at oprette din søgeagent, skal du give den et navn." />
              )}
              {/* {showError && (
              <AlertToast errorText="Vi kan desværre ikke oprette en ny søgeagent, da du allerede har en aktiv søgeagent med disse filtre." />
            )} */}
              <div className={styles.contactPreferences}>
                <h3 className={styles.title}>Hvordan vil du have besked?</h3>
                <div className={styles.contactCheckboxes}>
                  <label className="label">
                    <input
                      onChange={handleCheckboxChange}
                      checked={emailNotification}
                      type="checkbox"
                      name="emailNotification"
                    />
                    {serverResponse?.emailNotificationLabel || 'Email'}
                  </label>
                  <label className="label">
                    <input
                      onChange={handleCheckboxChange}
                      checked={pushNotification}
                      type="checkbox"
                      name="pushNotification"
                    />
                    {serverResponse?.pushNotificationLabel || 'Push besked'}
                  </label>
                </div>
                <p className={styles.noCostOptOut}>
                  Servicen er gratis og kan til enhver tid afmeldes
                </p>
              </div>
            </>
          )}
        </>
      </DialogBody>
      {saveSearchSuccess ? null : (
        <DialogActions>
          <DialogClose>
            <Button variant="Secondary" onClick={() => {}}>
              Fortryd
            </Button>
          </DialogClose>
          <Button onClick={postSaveSearch}>Bekræft oprettelse</Button>
        </DialogActions>
      )}
    </>
  );
};
