type SortCarretProps = {
  asc?: boolean;
  desc?: boolean;
} & React.SVGProps<SVGSVGElement>;

export const SortCarret = ({ asc, desc, ...props }: SortCarretProps) => {
  if (!!asc)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={20}
        width={20}
        viewBox="0 0 20 20"
        fill="currentColor"
        {...props}
      >
        <path d="M5 12.5L10 7.5L15 12.5H5Z" />
      </svg>
    );

  if (!!desc) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={20}
        width={20}
        viewBox="0 0 20 20"
        fill="currentColor"
        {...props}
        style={{ transform: 'rotate(180deg)' }}
      >
        <path d="M5 12.5L10 7.5L15 12.5H5Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={20}
      width={20}
      viewBox="0 0 20 20"
      fill="#B2B7BC"
      {...props}
    >
      <path d="M5 9L10 4L15 9H5Z" className="up" />
      <path d="M10 16L5 11H15L10 16Z" className="down" />
    </svg>
  );
};

SortCarret.displayName = 'SortCarret';
export default SortCarret;
