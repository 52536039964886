/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import {
  dehydrate,
  QueryClient,
  type DehydratedState,
} from '@tanstack/react-query';
import styles from '../../styles/srp.module.scss';
import {
  getMetaTexts,
  getSearchResultsByUrl,
  getSeoContentByUrl,
  getUserDetails,
  type UserDetailsDto,
} from '../../api/search';
import { SEOBreadcrumbs } from '@/atoms/SEOBreadcrumbs';
import { Listing } from '@/organisms/Listing';
import { useSearch } from '@/hooks/useSearch';
import { Pagination } from '@/molecules/Pagination';
import { SearchActions } from '@/molecules/SearchActions';
import { DisplayMode, DisplayModeSelect } from '@/atoms/DisplayModeSelect';
import { Sorting } from '@/molecules/Sorting';
import { AllFilters } from '@/molecules/filters/AllFilters';
import type { FilledContext } from 'react-helmet-async';
import Badge from '@/atoms/Badge';
import Button from '@/atoms/Button';
import FilterIcon from '@/icons/FilterIcon';
import { trackPulseUIElementEvent } from '@smd/tracking';
import {
  SmdBasSrpModelsSearchInfo,
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpWebApiModelsSeoData,
  type EcgdkTrackingDataLayerDtoDataLayerDto,
  type EcgdkTrackingPulseDataLayerDtoPulseDataLayerDto,
} from 'types/api';
import { useCookie } from '@/hooks/useCookie';
import { CarAbundance } from '@/molecules/CarAbundance';
import { useIntermingleAds } from '@/hooks/useIntermingleAds';
import * as Ads from '@smd/advertising';
import cx from 'classnames';
import { SearchAgent } from '@/molecules/SearchAgent';
import SEOListings from '@/atoms/SEOListings';
import { FreeTextSearchFilter } from '@/molecules/filters/special/FreeTextSearchFilter';
import FiltersCaption from '@/atoms/FiltersCaption';
import { ErrorMessage } from '@/atoms/ErrorMessage';
import { NoResultsMessage } from '@/atoms/NoResultsMessage';
import { IncomingHttpHeaders } from 'http';
import { SrpChips } from '@/molecules/SrpChips';
import { SrpViewProvider } from '@/hooks/useSrpView';
import { SeoBottomPage } from '@/molecules/PageSeo';
import { customColumns, ListingHeader } from '@/atoms/ListingHeader';
import { useRouter } from 'next/router';
import { RelatedListings } from '@/organisms/RelatedListings';
import type { MeridianDataLayerValue } from '@smd/datalayer-typings';
import { fetchAds } from 'api/common';
import {
  AmplitudeExperimentsContext,
  ExperimentVariant,
  getExperimentVariants,
  makeExperimentObject,
  PULSE_DATA_COOKIE_NAME,
  pulseExperimentObject,
} from '@/utils/amplitudeExperiments';
import Cookies from 'js-cookie';

export namespace SearchResults {
  export type Props = {
    initialSearchRequest: SmdBasSrpModelsSearchRequest;
    metaTexts: SmdBasSrpModelsSearchInfo;
    seoContent: SmdBasSrpWebApiModelsSeoData;
    dehydratedState: DehydratedState;
    helmetContext: Partial<FilledContext>;
    dataLayer: EcgdkTrackingDataLayerDtoDataLayerDto & MeridianDataLayerValue;
    pulse: EcgdkTrackingPulseDataLayerDtoPulseDataLayerDto;
    cookies: Record<string, string>;
    ads: Ads.Core.Config.Completed | null;
    experimentVariants: ExperimentVariant[];
  };
}

export const SearchResults = ({
  initialSearchRequest,
  metaTexts,
  seoContent,
  experimentVariants,
}: SearchResults.Props) => {
  const [variants, setVariants] =
    React.useState<ExperimentVariant[]>(experimentVariants);
  const {
    data,
    searchRequest,
    selectedCount,
    isFetching,
    isDebounceActive,
    setSort,
    error,
    getValue,
    setValue,
    toggleValue,
    clear,
    canBeEmpty,
    getLabelValue,
    getFiltersKeys,
    getFiltersOptionValues,
  } = useSearch(initialSearchRequest, variants);

  const router = useRouter();
  const freeTextSearchContainerRef = React.useRef<HTMLDivElement>(null);
  const pathInitiallyIncludedOpenFilter = React.useRef(
    !!router.asPath.includes('open-filter')
  ).current;
  const [allFiltersOpen, setAllFiltersOpen] = React.useState(
    pathInitiallyIncludedOpenFilter
  );
  const [displayMode, setDisplayMode] = useCookie('DisplayMode', 'Gallery');
  const [customColumn, setCustomColumn] = useCookie('CustomColumn', 'kml');
  const [sortAlert, setSortAlert] = React.useState(false); //Right now there is only one sortAlert (location not available)
  const intermingled = useIntermingleAds(data?.listings ?? []);

  React.useEffect(() => {
    if (experimentVariants.length == 0) {
      let intervalId: NodeJS.Timeout;
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId); // Stop checking after 1000ms
      }, 1000);

      intervalId = setInterval(() => {
        const cookieValue = Cookies.get(PULSE_DATA_COOKIE_NAME);
        if (cookieValue !== undefined) {
          clearInterval(intervalId); // Stop checking if cookie is not undefined
          clearTimeout(timeoutId); // Clear the timeout if cookie is found before 1000ms
          //Add all variants relevant to the experiment here
          getExperimentVariants(cookieValue).then((variants) => {
            setVariants(variants);
          });
        }
      }, 200); // Check every 200ms
    }
  }, [experimentVariants.length]);

  const handleChangeDisplayMode = (mode: DisplayMode) => {
    trackPulseUIElementEvent({
      elementName: 'DisplayModeChange',
      eventDescription: mode === 'Gallery' ? 'Set to Gallery' : 'Set to List',
    });
    setDisplayMode(mode);
  };

  const numberOfSelectedFilters =
    data?.filterOptions
      ?.filter((f) => f.type === 'Grouping')
      .reduce((acc, filter) => acc + selectedCount[filter.key!], 0) ?? 0;

  const handleAllFiltersOpenChange = (open: boolean) => {
    if (!open) {
      setAllFiltersWasClosed(true);
      trackPulseUIElementEvent({
        elementName: 'CloseAllFilters',
        eventDescription: 'Close All Filters',
      });
    }
    setAllFiltersOpen(open);
  };

  const handleSort = (sortBy: string, sortOrder: string) => {
    if (customColumns.includes(sortBy)) {
      setCustomColumn(sortBy);
    }
    if (
      sortBy == 'distance' &&
      !searchRequest?.selectedFilters?.GeoLocation &&
      !searchRequest?.selectedFilters?.ZipCode
    ) {
      const success = (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords;
        setValue(
          'GeoLocation',
          { firstValue: latitude, secondValue: longitude },
          'SearchSRP'
        );
      };
      const error = () => {
        setSortAlert(true);
        setSort(
          searchRequest.selectedSorting?.sortBy ?? '',
          searchRequest.selectedSorting?.sortOrder ?? ''
        );
        return;
      };
      'geolocation' in navigator &&
        navigator.geolocation.getCurrentPosition(success, error);
    }
    if (
      makeExperimentObject(variants).ValereTest === 'relevance' &&
      sortBy === ''
    ) {
      sortBy = 'relevance';
      sortOrder = 'asc';
    }
    setSort(sortBy, sortOrder);

    trackPulseUIElementEvent({
      elementName: 'Sort',
      eventDescription: sortBy,
      experiments: pulseExperimentObject(variants),
    });
  };

  const freeTextSearchValue = getValue('FreeText', 'Search') as
    | string
    | undefined;

  const showSeo =
    !(!!data?.searchRequest?.page && data.searchRequest.page > 1) &&
    JSON.stringify(initialSearchRequest) ===
      JSON.stringify(data?.searchRequest);

  const [allFiltersHasBeenClosed, setAllFiltersWasClosed] =
    React.useState(false);

  const allFiltersWillNotCoverAds =
    allFiltersHasBeenClosed || !pathInitiallyIncludedOpenFilter;

  Ads.Utilities.enable.use.when(allFiltersWillNotCoverAds);

  return (
    <AmplitudeExperimentsContext.Provider value={variants}>
      <SrpViewProvider
        isFetching={isFetching}
        isDebounceActive={isDebounceActive}
        backendSearchRequest={data?.searchRequest}
        clientSearchRequest={searchRequest}
        tracking={data?.tracking}
        experimentVariants={variants}
      >
        <Head>
          <title>
            {data?.title ??
              'Køb brugte og nye biler på Bilbasen - Danmarks største bilmarked'}
          </title>
          {data?.canonicalUrl ? (
            <link rel="canonical" href={data.canonicalUrl} />
          ) : null}
        </Head>
        <SEOBreadcrumbs crumbs={data?.breadcrumbs ?? []} />
        <SEOListings listings={data?.listings ?? []} hits={data?.hits} />
        <div className={styles.stickyActions}>
          <SearchActions>
            <div
              className={styles.freeTextSearchContainer}
              ref={freeTextSearchContainerRef}
            >
              <FreeTextSearchFilter
                id="SearchSRP"
                value={freeTextSearchValue ?? ''}
                onChange={(val) => setValue('FreeText', val, 'SearchSRP')}
                containerRef={freeTextSearchContainerRef}
              />
            </div>
            <div className={styles.searchAgent}>
              <SearchAgent selectedFilters={searchRequest} position="SRP" />

              <AllFilters
                open={!error && !!data?.filterOptions && allFiltersOpen}
                data={data}
                searchRequest={searchRequest}
                selectedCount={selectedCount}
                metaTexts={metaTexts}
                setValue={setValue}
                toggleValue={toggleValue}
                getValue={getValue}
                getLabelValue={getLabelValue}
                onClear={clear}
                onOpenChange={handleAllFiltersOpenChange}
              >
                <Button
                  variant="Primary"
                  data-e2e="all-filters-button"
                  startElement={
                    <FilterIcon
                      style={{
                        display: 'inline-flex',
                        flex: 'none',
                        width: 'auto',
                      }}
                    />
                  }
                  endElement={
                    numberOfSelectedFilters > 0 ? (
                      <Badge number={numberOfSelectedFilters} />
                    ) : null
                  }
                  onClick={() => handleAllFiltersOpenChange(true)}
                  className={styles.filtersButton}
                >
                  <FiltersCaption caption="Alle filtre" shortCaption="Filtre" />
                </Button>
              </AllFilters>
            </div>
          </SearchActions>
          <SrpChips
            selectedFilters={searchRequest?.selectedFilters}
            getFiltersKeys={getFiltersKeys}
            getValue={getValue}
            getLabelValue={getLabelValue}
            getFiltersOptionValues={getFiltersOptionValues}
            toggleValue={toggleValue}
            clear={clear}
            canBeEmpty={canBeEmpty}
          />
        </div>
        <div className={styles.top}>
          <CarAbundance availableCars={data?.hits} />
          <div className={cx(styles.sortingDisplayControls, 'caption')}>
            <Sorting
              current={searchRequest?.selectedSorting}
              options={data?.sortOptions ?? []}
              onChange={handleSort}
              sortAlert={sortAlert}
              setSortAlert={setSortAlert}
            />
            <DisplayModeSelect
              mode={(displayMode as DisplayMode) ?? 'Gallery'}
              onChange={handleChangeDisplayMode}
            />
          </div>
        </div>
        {(data?.carOfTheYearBanners?.length ?? 0) > 0 ? (
          <div className={styles.carOfTheYear}>
            {data?.carOfTheYearBanners?.map((banner, index) => (
              <a key={index} href={banner.linkUrl ?? '#'} target="_blank">
                <img
                  src={banner.imageUrl ?? ''}
                  alt={banner.text ?? 'Årets Brugtbil'}
                />
              </a>
            ))}
          </div>
        ) : null}

        <section
          className={cx(styles.results, { listView: displayMode === 'List' })}
        >
          {displayMode === 'List' && (
            <ListingHeader
              customColumnKey={customColumn}
              currentSort={searchRequest?.selectedSorting}
              sortOptions={data?.sortOptions ?? undefined}
              onChangeSort={handleSort}
            />
          )}
          {!isFetching && !!error && <ErrorMessage />}
          {!isFetching && data?.listings?.length === 0 && <NoResultsMessage />}

          {!!intermingled &&
            !error &&
            intermingled.map((entry, index) =>
              'features' in entry ? ( // TODO: This is failing type specification for the IntermingleAdSlot forcing us to manually cast it
                <Listing
                  key={entry.uri}
                  data={entry}
                  customColumnKey={customColumn}
                  currentSort={data?.searchRequest?.selectedSorting?.sortBy}
                />
              ) : (
                'item' in entry &&
                Ads.Display.TypeGuard.Slot.is(entry) && (
                  <div
                    className={cx('spanAllColumns', styles.intermingleAdRow)}
                    key={entry.id}
                  >
                    <Ads.Display.Slot properties={entry.item} />
                  </div>
                )
              )
            )}
        </section>
        {(data?.hits ?? 0) > 0 && (
          <Pagination
            next={data?.pagination?.next}
            previous={data?.pagination?.previous}
            maxPages={Math.ceil(
              (data?.hits ?? 0) / Number(process.env.NEXT_PUBLIC_PAGESIZE ?? 30)
            )}
            page={data?.searchRequest?.page ?? 1}
          />
        )}
        <RelatedListings
          backendSearchRequest={data?.searchRequest || initialSearchRequest}
        />
        {showSeo ? <SeoBottomPage data={seoContent} /> : null}
      </SrpViewProvider>
    </AmplitudeExperimentsContext.Provider>
  );
};

// TODO: Consider moving these to a separate file
/**
 * We create a globalQueryClient for data shared across request e.g. filter popovers/notices
 * In addition, we make a timeout in the fetch, so that we wait at most META_TEXT_FETCH_TIMEOUT_MS for the texts
 */
const globalQueryClient = new QueryClient();
const fetchMetaTexts = () =>
  new Promise<any>((res) => {
    setTimeout(
      () => res({}),
      Number(process.env.META_TEXT_FETCH_TIMEOUT_MS ?? 1000)
    );

    try {
      const textPromise = globalQueryClient.fetchQuery({
        queryKey: ['texts'],
        queryFn: () => getMetaTexts(),
        staleTime: Number(process.env.META_TEXT_STALE_MIN ?? 5) * 1000 * 60, // Cache will be updated if older than this
        cacheTime:
          Number(process.env.META_TEXT_CACHE_CLEAN_MIN ?? 60 * 2) * 1000 * 60, // Cache will be cleared if not used within this period
      });
      textPromise.then(res);
    } catch (error) {
      res({});
    }
  });

const fetchUserDetails = (headers?: IncomingHttpHeaders) =>
  new Promise<UserDetailsDto>((res) => {
    const timeoutId = setTimeout(() => res({}), 1000);
    getUserDetails(headers).then((data) => {
      clearTimeout(timeoutId);
      res(data);
    });
  });

const fetchSeoTexts = async (url: string) =>
  new Promise<any>((res) => {
    setTimeout(
      () => res({}),
      Number(process.env.META_TEXT_FETCH_TIMEOUT_MS ?? 1000)
    );

    try {
      const seoTextPromise = globalQueryClient.fetchQuery({
        queryKey: ['seo', url],
        queryFn: () => getSeoContentByUrl(url),
        staleTime: Number(process.env.META_TEXT_STALE_MIN ?? 5) * 1000 * 60, // Cache will be updated if older than this
        cacheTime:
          Number(process.env.META_TEXT_CACHE_CLEAN_MIN ?? 60 * 2) * 1000 * 60, // Cache will be cleared if not used within this period
      });

      seoTextPromise.then(res);
    } catch (err: unknown) {
      res({});
    }
  });

export const getServerSideProps: GetServerSideProps<
  SearchResults.Props
> = async (context) => {
  performance.mark('getServerSideProps-start');
  const queryClient = new QueryClient();
  // resolvedUrl will be relative, so in order for the URL to work with our API, we prepend a "fake" host
  const url = new URL('https://www.bilbasen.dk' + context.resolvedUrl);

  const resultsPromise = getSearchResultsByUrl(
    url.href,
    undefined,
    context.req.headers
  );
  const userPromise = fetchUserDetails(context.req.headers);

  // Merge the base dataLayer with the user-specific dataLayer:
  const dataLayerPromise = (async () => {
    const [results, user] = await Promise.all([resultsPromise, userPromise]);
    return Object.assign({}, results.tracking?.dataLayer, user.dataLayer);
  })();

  const adsPromise = dataLayerPromise.then((dataLayer) =>
    fetchAds(dataLayer, context)
  );

  // Run all our external requests in parallell
  const [results, user, metaTexts, seoContent, dataLayer, ads] =
    await Promise.all([
      resultsPromise,
      userPromise,
      fetchMetaTexts(),
      fetchSeoTexts(url.href),
      dataLayerPromise,
      adsPromise,
    ]);

  // Cookies to send from the server request to the client - these are needed when doing SSR, as no cookies will not be available on the SSR client
  const whiteListedCookies = JSON.parse(
    process.env.WHITELISTED_COOKIES ??
      '["DisplayMode", "CustomColumn", "beta-modal-shown", "GdprConsent", "GdprConsent-Custom", "_pulse2data"]'
  ) as string[];

  const cookies = Object.keys(context.req.cookies).reduce((acc, key) => {
    if (whiteListedCookies.includes(key)) {
      acc[key] = context.req.cookies[key]!;
    }
    return acc;
  }, {} as Record<string, string>);

  const experimentVariants = await getExperimentVariants(
    cookies[PULSE_DATA_COOKIE_NAME]
  );

  // Client-side we will be using the SearchRequest as a cache key, so we "warm up" the QueryClient cache here with the data received
  queryClient.setQueryData(['search', results.searchRequest], results);
  queryClient.setQueryData(['user'], user);
  const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient))); // TODO: Avoid undefined values
  queryClient.clear(); // Clear cache

  const helmetContext: Partial<FilledContext> = {};

  // Measure performance of the NextJS backend and api call to get-by-url endpoint
  performance.mark('getServerSideProps-end');

  const measure = performance.measure('getServerSideProps', {
    start: 'getServerSideProps-start',
    end: 'getServerSideProps-end',
    detail: url.href,
  });

  if (measure.duration > 250) {
    console.warn(
      `Data fetch in getServerSideProps took a long time: ${
        measure.duration
      }ms (url: ${measure.detail ?? 'Unknown'})`
    );
  }

  return {
    props: {
      dehydratedState,
      initialSearchRequest: results.searchRequest ?? {},
      dataLayer,
      pulse: results.tracking?.pulse ?? {},
      helmetContext,
      cookies,
      metaTexts,
      seoContent,
      ads,
      experimentVariants,
    } satisfies SearchResults.Props,
  };
};

export default SearchResults;
