import styles from './Badge.module.scss';

type Props = {
  children?: React.ReactNode;
  number?: number;
  max?: number;
};

export const Badge = ({ children, number, max }: Props) => {
  if (typeof number !== 'number') {
    return <span className={styles.badge} />;
  }

  if (!children) {
    return (
      <span className={styles.badge}>
        {typeof max === 'number' && number > max ? `${max}+` : number}
      </span>
    );
  }

  return (
    <span>
      {children}
      <span className={styles.badge}>{number}</span>
    </span>
  );
};

export default Badge;
