export const FavoriteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16" cy="16" r="16" className="circle" />
    <path
      className="path"
      d="M16.0019 23.5L10.0345 16.892C9.50834 16.3248 9.16331 15.6074 9.04523 14.8351C8.92714 14.0628 9.04159 13.272 9.37337 12.5677V12.5677C9.60856 12.042 9.96611 11.5831 10.4148 11.2313C10.8634 10.8794 11.3896 10.6452 11.9472 10.5492C12.5048 10.4532 13.077 10.4982 13.6136 10.6803C14.1503 10.8625 14.6352 11.1763 15.0259 11.5942L16.0019 12.6293L16.9763 11.5951C17.3668 11.1775 17.8515 10.864 18.3878 10.682C18.9242 10.5 19.496 10.455 20.0533 10.5509C20.6106 10.6468 21.1365 10.8808 21.585 11.2324C22.0335 11.5839 22.391 12.0424 22.6262 12.5677V12.5677C22.9583 13.2718 23.0729 14.0625 22.9548 14.8348C22.8367 15.607 22.4915 16.3243 21.9651 16.8912L16.0019 23.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

FavoriteIcon.displayName = 'FavoriteIcon';
export default FavoriteIcon;
