/* eslint-disable @next/next/no-img-element */
import { formatDKK } from '@/utils';
import Carousel from '@/molecules/Carousel';
import { CarouselIndicator } from '@/atoms/CarouselIndicator';
import { ImageWithFallback } from '@/atoms/ImageWithFallback';
import styles from './Listing.module.scss';
import LocationIcon from '@/icons/LocationIcon';
import React from 'react';
import {
    SmdBasSrpModelsPriceTypeLabel,
    SmdBasSrpWebApiModelsWebListing,
} from 'types/api';
import ThreeSixtyImageIcon from '@/icons/ThreeSixtyImageIcon';
import VideoIcon from '@/icons/VideoIcon';
import cx from 'classnames';
import Favorites from '@/molecules/Favorites';
import { trackPulseListingItemEvent } from '@smd/tracking';
import {ListingDetails} from "@/molecules/ListingDetails";
import {AmplitudeExperimentsContext, pulseExperimentObject} from "@/utils/amplitudeExperiments";

interface Props {
  data: SmdBasSrpWebApiModelsWebListing;
  customColumnKey?: string;
  currentSort?: string | null;
}

const formatPrice = (
  price: number,
  priceType: SmdBasSrpModelsPriceTypeLabel
) => {
  if (priceType !== 'CallForPrice' && typeof price !== 'number') {
    return 'Ukendt';
  }

  switch (priceType) {
    case 'CallForPrice':
      return 'Ring for pris';
    case 'LeasingInclVat':
      return formatDKK(price) + '/md';
    case 'LeasingExclVat':
      return formatDKK(price) + '/md (ex. moms)';
    case 'WithoutTax':
      return formatDKK(price) + ' (Uden afgift)';
    case 'Wholesale':
      return formatDKK(price) + ' (Engros/CVR)';
    case 'RetailExclVat':
      return formatDKK(price) + ' (ex. moms)';
    default:
      return formatDKK(price);
  }
};

const Price = ({
  price,
  priceType,
}: {
  price: number;
  priceType: SmdBasSrpModelsPriceTypeLabel;
}) => (
  <h3 className="font-bold color-primary">{formatPrice(price, priceType)}</h3>
);

export const Listing = React.memo(
  ({ data, customColumnKey = 'kml', currentSort }: Props) => {
    const experiments = React.useContext(AmplitudeExperimentsContext);
    const { externalId, uri, make, model, variant, location, description } =
      data;
    const { price, priceType } = data.price!;

    const images =
      data.media
        ?.filter((m) => m.mediaType === 'Picture')
        .map((p) => p.url ?? '') ?? [];

    const dealerLogo =
      data.media?.filter((m) => m.mediaType === 'DealerLogo') ?? [];
    const carOfTheYearLogo = data.media?.filter(
      (c) => c.mediaType === 'CarOfTheYear'
    );

    const newAdvertising = data?.features?.some(
      (newListing) => newListing === 'NewListing'
    );

    const has360image = data.media?.some(
      (m) => m.mediaType === 'ThreeSixtyPicture'
    );
    const hasVideo = data.media?.some((m) => m.mediaType === 'Video');

    const locationDetails = [location?.city, location?.region]
      .filter(Boolean)
      .join(', ');
    const distanceToSeller =
      typeof location?.distanceToSeller === 'number'
        ? Math.round(location?.distanceToSeller)
        : undefined;

    if (!uri) return null;

    const altText = [make, model, variant].filter(Boolean).join(' ');

    const handleClick = () => {
      if (typeof externalId !== 'undefined') {
        trackPulseListingItemEvent({
            classifiedAdId: `${externalId}`,
            experiments: pulseExperimentObject(experiments)
        });
      }
    };

    return (
      <article className={cx(styles.listing)}>
        {/* The anchor below is covering the entire article - even though it appears to be empty */}
        <a href={uri} className={styles.link} onClick={handleClick} />
        <div className={styles.listingWrap}>
          <div className={styles.top}>
            {dealerLogo.length > 0 && (
              <div className={styles.banners}>
                <img
                  className={styles.dealerLogoCard}
                  src={dealerLogo?.[0].url ?? undefined}
                  alt="Forhandlerlogo"
                />
              </div>
            )}
          </div>
          <div className={styles.media}>
            <Carousel
              className={styles.image}
              overlay={(index: number) => (
                <>
                  {hasVideo || has360image ? (
                    <div className={styles.mediaIcons}>
                      {has360image ? (
                        <ThreeSixtyImageIcon height={18} width={18} />
                      ) : null}
                      {hasVideo ? <VideoIcon height={18} width={18} /> : null}
                    </div>
                  ) : null}
                  <CarouselIndicator
                    className={styles.dots}
                    length={images?.length ?? 0}
                    index={index}
                  />
                  {typeof externalId !== 'undefined' && (
                    <Favorites
                      id={externalId}
                      isfavorite={data.isFavorite ?? false}
                      className={styles.favoriteButton}
                    />
                  )}
                  {newAdvertising && (
                    <div className={styles.newListingBadge}>Ny annonce</div>
                  )}
                </>
              )}
            >
              {images && images.length > 0 ? (
                images.map((image, index) => (
                  <a
                    key={index}
                    href={uri}
                    aria-hidden="true"
                    tabIndex={-1}
                    className={styles.imageLink}
                    onClick={handleClick}
                  >
                    <ImageWithFallback
                      src={image}
                      // sizes={'300'}
                      sizes="(max-width: 500px) 100vw, (max-width: 800px) 50vw, 33vw"
                      fill
                      alt={altText}
                      className="object-fit-cover"
                    />
                  </a>
                ))
              ) : (
                <a
                  href={uri}
                  aria-hidden="true"
                  tabIndex={-1}
                  className={styles.imageLink}
                  onClick={handleClick}
                >
                  <ImageWithFallback
                    fill
                    alt={altText}
                    className="object-fit-cover"
                  />
                </a>
              )}
            </Carousel>
          </div>
          <div className={styles.makeModel}>
            <div>
              <h3 className="font-bold">{make + ' ' + model}</h3>
              {variant}
            </div>
            {(carOfTheYearLogo?.length ?? 0) > 0 && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                className={styles.carOfTheYear}
                src={carOfTheYearLogo?.[0].url ?? undefined}
                alt="Årets brugt bil på Bilbasen"
              />
            )}
          </div>
          <div className={styles.price}>
            <Price price={price!} priceType={priceType!} />
          </div>
          <div className={styles.details}>
            {(carOfTheYearLogo?.length ?? 0) > 0 && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                className={styles.carOfTheYear}
                src={carOfTheYearLogo?.[0].url ?? undefined}
                alt="Årets brugt bil på Bilbasen"
              />
            )}
            <ListingDetails
                details={data.properties}
                sortBy={currentSort} />
          </div>
          <div className={styles.properties}>
            <div>
              {data.properties?.['firstregistrationdate']?.displayTextShort ??
                '-'}
            </div>
            <div>{data.properties?.['mileage']?.displayTextShort ?? '-'}</div>
            <div>
              {data.properties?.[customColumnKey]?.displayTextShort ?? '-'}
            </div>
          </div>
          {!!description && (
            <div className={styles.description}>{description}</div>
          )}
          <div className={styles.location}>
            <div>
              <LocationIcon />
              <span>
                {locationDetails.length > 0 ? locationDetails : 'Ukendt'}
                {typeof distanceToSeller === 'number' &&
                  ` (${distanceToSeller} km)`}
              </span>
            </div>
            {(dealerLogo?.length ?? 0) > 0 && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                className={styles.dealerLogoList}
                src={dealerLogo?.[0].url ?? undefined}
                alt="Forhandlerlogo"
              />
            )}
          </div>
        </div>
      </article>
    );
  }
);

Listing.displayName = 'Listing';
