import React, { Suspense, useState } from 'react';
import FavoriteIcon from '@/icons/FavoriteIcon';
import styles from './Favorites.module.scss';
import { useUser } from '@/hooks/useUser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFavorite, saveFavorite } from 'api/search';
import { Tooltip } from '@/atoms/Tooltip';
import cx from 'classnames';
import { canUseDOM } from '@smd/utilities';
import {
  PulseStandardEventIntent,
  trackPulseFavoriteAdEvent,
} from '@smd/tracking';
import {AmplitudeExperimentsContext, pulseExperimentObject} from "@/utils/amplitudeExperiments";

const LoginNudge = React.lazy(
  () => import('./LoginNudge' /*webpackChunkName: "lazy-favorite-login-nudge"*/)
);

const vibrate = () => {
  if (canUseDOM() && 'vibrate' in window.navigator) {
    window.navigator.vibrate(300);
  }
};
interface IFavoriteProps {
  id: number;
  isfavorite: boolean;
  className?: string;
}

const Favorites: React.FC<IFavoriteProps> = ({
  id,
  isfavorite: isfavoriteProp,
  className,
}: IFavoriteProps) => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useUser();
  const experiments = React.useContext(AmplitudeExperimentsContext);
  const [isFavorite, setIsfavorite] = useState(isfavoriteProp);
  const [openNudge, setOpenNudge] = useState(false);

  const deleteFavoriteMutation = useMutation({
    mutationFn: ({ id }: { id: number }) => deleteFavorite(id),
    onSuccess: () => {
      trackPulseFavoriteAdEvent({
        classifiedAdId: id.toString(),
        intent: PulseStandardEventIntent.Unlike,
        experiments: pulseExperimentObject(experiments)
      });
      queryClient.invalidateQueries(['user']);
      vibrate();
    },
  });
  const addFavoriteMutation = useMutation({
    mutationFn: ({ id }: { id: number }) => saveFavorite(id),
    onSuccess: () => {
      trackPulseFavoriteAdEvent({
        classifiedAdId: id.toString(),
        intent: PulseStandardEventIntent.Like,
        experiments: pulseExperimentObject(experiments)
      });
      queryClient.invalidateQueries(['user']);
      vibrate();
    },
  });

  const handleFavoriteClick = () => {
    if (!isAuthenticated) {
      setOpenNudge(true);
      return;
    }

    if (isFavorite) {
      setIsfavorite(false);
      deleteFavoriteMutation.mutate(
        { id },
        { onError: () => setIsfavorite(true) }
      );
    } else {
      setIsfavorite(true);
      addFavoriteMutation.mutate(
        { id },
        { onError: () => setIsfavorite(false) }
      );
    }
  };

  return (
    <>
      <Tooltip content={isFavorite ? 'Fjern favorit' : 'Tilføj favorit'}>
        <button
          className={cx(styles.favoritesButton, className)}
          onClick={handleFavoriteClick}
        >
          <FavoriteIcon
            className={cx(styles.favoritesIcon, {
              [styles.active]: isFavorite,
            })}
          />
        </button>
      </Tooltip>
      {openNudge && (
        <Suspense fallback={<></>}>
          <LoginNudge open={openNudge} setNudgeOpen={setOpenNudge} />
        </Suspense>
      )}
    </>
  );
};

export default Favorites;
