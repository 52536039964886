import React from 'react';
import { InfoPopover } from './InfoPopover';
import styles from './CarAbundance.module.scss';
import { formatNumber } from '@/utils';

interface Props {
  availableCars?: number;
}

export const CarAbundance = ({ availableCars }: Props) => {
  return (
    <>
      <div className={styles.availableCars} data-e2e="abundance">
        {'Viser: ' +
          (typeof availableCars !== 'undefined'
            ? formatNumber(availableCars)
            : 'alle') +
          ' biler til salg'}
        <InfoPopover
          placement="bottom"
          content={
            <>
              <p style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                Hvorfor ser du disse biler først?
              </p>
              <div>
                Find ud af mere om vores søgemaskine, og hvordan vi rangordner
                biler.{' '}
              </div>
            </>
          }
          url={'https://www.bilbasen.dk/brugeraftale#annoncerRangordning'}
        />
      </div>
    </>
  );
};
