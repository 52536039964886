import Button from '@/atoms/Button';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogBody,
  DialogActions,
  DialogClose,
} from '@/atoms/Dialog';
import styles from './AllFilters.module.scss';
import React from 'react';
import { Chips } from '../Chips';
import { formatNumber } from '@/utils';
import {
  SmdBasSrpModelsSearchInfo,
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpModelsSelectedFilterValue,
  SmdBasSrpWebApiModelsWebSearchResponse,
} from 'types/api';
import { SearchAgent } from '../SearchAgent';
import dynamic from 'next/dynamic';
import FiltersCaption from '@/atoms/FiltersCaption';
import { useSrpView } from '@/hooks/useSrpView';

const DynamicFilterFactory = dynamic(
  () => import('./FilterFactory').then((mod) => mod.FilterFactory),
  {
    ssr: false,
  }
);

type Props = {
  open?: boolean;
  children?: React.ReactNode;
  data?: SmdBasSrpWebApiModelsWebSearchResponse;
  searchRequest?: SmdBasSrpModelsSearchRequest;
  selectedCount: Record<string, number>;
  getValue: (
    key: string
  ) =>
    | string
    | number
    | boolean
    | SmdBasSrpModelsSelectedFilterValue[]
    | SmdBasSrpModelsSelectedFilterValue
    | undefined;
  getLabelValue: (key: string, value: string | number) => string;
  setValue: (key: string, value: any, id?: any) => void;
  toggleValue: (key: string, value: any) => void;
  onClear: (key?: string) => void;
  onOpenChange?: (open: boolean) => void;
  metaTexts: SmdBasSrpModelsSearchInfo;
};

export const AllFilters = ({
  open = false,
  children,
  data,
  searchRequest,
  selectedCount,
  metaTexts,
  getValue,
  getLabelValue,
  setValue,
  toggleValue,
  onClear,
  onOpenChange,
}: Props) => {
  const { toggleSrpViewTracking, newSrpView } = useSrpView();
  const filterBodyRef = React.useRef<HTMLDivElement>(null);
  const [groupState, setGroupState] = React.useState<Record<string, boolean>>(
    {}
  );

  const toggleGroup = (key: string) => {
    setGroupState((prev) => ({ ...prev, [key]: !(prev[key] ?? false) }));
  };

  const handleOpenChange = (open: boolean) => {
    onOpenChange && onOpenChange(open);
    toggleSrpViewTracking(!open && newSrpView); // we only want to track a view when the All Filters Dialog is closed & at least a filter value has changed/a new search has been made; otherwise, don't track a view
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      {children}

      <DialogContent
        transition={{
          initial: { transform: 'translateX(50px)' },
        }}
        className={styles.dialog}
        data-e2e="all-filters-dialog"
      >
        <DialogHeading
          style={{ width: '100%' }}
          subHeader={
            <Chips
              selectedFilters={searchRequest?.selectedFilters}
              getValue={getValue}
              getLabelValue={getLabelValue}
              toggleValue={toggleValue}
            />
          }
        >
          <FiltersCaption caption="Alle filtre" shortCaption="Filtre" />
        </DialogHeading>
        <DialogBody className={styles.dialogBody} ref={filterBodyRef}>
          {open && (
            <DynamicFilterFactory
              data={data?.filterOptions ?? []}
              metaTexts={metaTexts}
              getValue={getValue}
              setValue={setValue}
              toggleValue={toggleValue}
              toggleGroup={toggleGroup}
              clearValue={onClear}
              groupState={groupState}
              selectedCount={selectedCount}
              containerRef={filterBodyRef}
            />
          )}
        </DialogBody>
        <DialogActions>
          <Button
            data-e2e="reset-filters"
            onClick={() => onClear()}
            style={{
              textDecoration: 'underline',
              color: 'var(--color-primary)',
              fontWeight: 500,
              backgroundColor: 'transparent',
              outline: 'none',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            Nulstil
          </Button>
          <div style={{ display: 'flex', gap: '8px' }}>
            <SearchAgent
              selectedFilters={searchRequest ?? {}}
              position="AllFilters"
            />
            <DialogClose>
              <Button data-e2e="close-all-filters">
                Vis{' '}
                {typeof data?.hits !== 'undefined'
                  ? formatNumber(data?.hits)
                  : 'alle'}{' '}
                biler
              </Button>
            </DialogClose>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
