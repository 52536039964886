import React from 'react';
import {
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpWebApiModelsTracking,
} from 'types/api';
import { getPulsePageObject, updateDataLayer } from '@/utils';
import { trackPulseUIElementEvent } from '@smd/tracking';
import { compareDeep } from '@smd/utilities';

type SrpViewProviderProps = {
  children: React.ReactNode;
  isFetching: boolean;
  isDebounceActive: boolean;
  backendSearchRequest: SmdBasSrpModelsSearchRequest | undefined;
  clientSearchRequest: SmdBasSrpModelsSearchRequest;
  tracking: SmdBasSrpWebApiModelsTracking | undefined;
};

type TrackingData = { id?: any; key: string; value: any };

export const useSrpPulseClickTracking = (
  isFetching: SrpViewProviderProps['isFetching'],
  isDebounceActive: SrpViewProviderProps['isDebounceActive'],
  backendSearchRequest: SrpViewProviderProps['backendSearchRequest'],
  clientSearchRequest: SrpViewProviderProps['clientSearchRequest'],
  tracking: SrpViewProviderProps['tracking']
) => {
  const [canTrackFilterSet, setCanTrackFilterSet] = React.useState(false); // use this to signal that setValue() has been called for a filter
  const [canTrackFilterToggle, setCanTrackFilterToggle] = React.useState(false); // use this to signal that toggleValue() has been called for a filter
  const [shouldTrackClick, setShouldTrackClick] = React.useState(false); // use this to signal that a change has happened in the search request
  const filterSetData = React.useRef<TrackingData[]>([]); // use an array in order to make sure we are not missing any tracking data when setValue() is called multiple times & until the search is settled
  const filterToggleData = React.useRef<TrackingData[]>([]); // use an array in order to make sure we are not missing any tracking data when toggleValue() is called multiple times & until the search is settled

  const toggleSrpFilterSetTracking = React.useCallback(
    (canTrack: boolean, { id, key, value }: TrackingData) => {
      setCanTrackFilterSet(canTrack);
      filterSetData.current?.unshift({ id, key, value }); // add it at the start of the array, so we can consume it later from the array end & clean it up at the same time
    },
    []
  );

  const toggleSrpFilterToggleTracking = React.useCallback(
    (canTrack: boolean, { key, value }: TrackingData) => {
      setCanTrackFilterToggle(canTrack);
      filterToggleData.current?.unshift({ key, value }); // add it at the start of the array, so we can consume it later from the array end & clean it up at the same time
    },
    []
  );

  React.useEffect(() => {
    const searchRequestHasChanged = !compareDeep(
      backendSearchRequest,
      clientSearchRequest
    );

    if (searchRequestHasChanged) {
      // if there is a change in the search, enable srp click tracking
      setShouldTrackClick(true);
    }

    if (
      shouldTrackClick &&
      ((canTrackFilterSet && filterSetData.current.length > 0) ||
        (canTrackFilterToggle && filterToggleData.current.length > 0)) &&
      !isDebounceActive &&
      !isFetching &&
      tracking &&
      !searchRequestHasChanged // search is settled, after updates
    ) {
      updateDataLayer(tracking);

      const pulsePageObject = getPulsePageObject();

      if (pulsePageObject) {
        if (canTrackFilterSet && filterSetData.current.length > 0) {
          // consume the filterSetData array, making sure to track each corresponding data that is pending, before removing it from the array
          for (let i = filterSetData.current.length - 1; i >= 0; i--) {
            const { id, key, value } = filterSetData.current[i];
            trackPulseUIElementEvent({
              elementName: id ? `FilterSet${key}${id}` : `FilterSet${key}`,
              eventDescription:
                typeof value === 'string'
                  ? value
                  : JSON.stringify(value)?.replaceAll('"', "'"),
            });
            filterSetData.current.pop(); // remove the element to clean up the array
          }
          setCanTrackFilterSet(false);
        }

        if (canTrackFilterToggle && filterToggleData.current.length > 0) {
          // consume the filterToggleData array, making sure to track each corresponding data that is pending, before removing it from the array
          for (let i = filterToggleData.current.length - 1; i >= 0; i--) {
            const { key, value } = filterToggleData.current[i];
            trackPulseUIElementEvent({
              elementName: `FilterToggle${key}`,
              eventDescription:
                typeof value === 'string' ? value : JSON.stringify(value),
            });
            filterToggleData.current.pop(); // remove the element to clean up the array
          }
          setCanTrackFilterToggle(false);
        }
        // disable click tracking until specifically enabled again both from a change in search
        // and from setValue() or toggleValue() (in useSearch.tsx)
        setShouldTrackClick(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldTrackClick,
    tracking,
    isDebounceActive,
    isFetching,
    backendSearchRequest,
    canTrackFilterSet,
    canTrackFilterToggle,
  ]);

  return {
    shouldTrackClick,
    toggleSrpFilterSetTracking,
    toggleSrpFilterToggleTracking,
  };
};
