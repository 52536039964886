import React from 'react';
import { compareDeep } from '@smd/utilities';
import { trackPulsePageViewEvent } from '@smd/tracking';
import * as Ads from '@smd/advertising';
import { getPulsePageObject, updateDataLayer } from '@/utils';
import {
  ExperimentVariant,
  pulseExperimentObject,
} from '@/utils/amplitudeExperiments';
import type {
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpWebApiModelsTracking,
  EcgdkTrackingDataLayerDtoDataLayerDto,
} from 'types/api';

type SrpViewContextType = {
  newSrpView: boolean;
  toggleSrpViewTracking: (canTrack: boolean) => void;
} | null;

type SrpViewProviderProps = {
  children: React.ReactNode;
  isFetching: boolean;
  isDebounceActive: boolean;
  backendSearchRequest: SmdBasSrpModelsSearchRequest | undefined;
  clientSearchRequest: SmdBasSrpModelsSearchRequest;
  tracking: SmdBasSrpWebApiModelsTracking | undefined;
  onNewPageView?: () => void;
  experimentVariants?: ExperimentVariant[];
};

const SrpViewContext = React.createContext<SrpViewContextType>(null);

export const useSrpView = () => {
  const context = React.useContext(SrpViewContext);

  if (context == null) {
    throw new Error('Component must be wrapped in a <SrpViewContext.Provider>');
  }

  return context;
};

export const SrpViewProvider: React.FC<SrpViewProviderProps> = ({
  children,
  ...props
}) => {
  const value = useTrackSrpView(
    props.isFetching,
    props.isDebounceActive,
    props.backendSearchRequest,
    props.clientSearchRequest,
    props.tracking,
    props.onNewPageView,
    props.experimentVariants
  );
  return (
    <SrpViewContext.Provider value={value}>{children}</SrpViewContext.Provider>
  );
};

/**
 * Cases when we need to track a Page View, if the search request changes:
 * - Chip is removed
 * - Nulstil on Chips is pressed
 * - "All Filters" dialog is closed
 * - Free text search field on SRP is cleared with X or Escape
 * - Free text search field on SRP data is set (enter on suggestion, enter in the field, click on suggestion, click outside of the free text search field)
 */

const useTrackSrpView = (
  isFetching: SrpViewProviderProps['isFetching'],
  isDebounceActive: SrpViewProviderProps['isDebounceActive'],
  backendSearchRequest: SrpViewProviderProps['backendSearchRequest'],
  clientSearchRequest: SrpViewProviderProps['clientSearchRequest'],
  tracking: SrpViewProviderProps['tracking'],
  onNewPageView?: SrpViewProviderProps['onNewPageView'],
  experimentVariants?: ExperimentVariant[]
) => {
  const [canTrackSrpView, setCanTrackSrpView] = React.useState(true); // use this to signal that one of the cases for triggering a page view, has happened
  const [newSrpView, setNewSrpView] = React.useState(true); // use this to signal that a change has happened in the search request
  const toggleSrpViewTracking = React.useCallback(
    (canTrack: boolean) => setCanTrackSrpView(canTrack),
    []
  );

  const lastDataLayerForAdsRefresh = React.useRef<
    EcgdkTrackingDataLayerDtoDataLayerDto | undefined
  >();

  React.useEffect(() => {
    const searchRequestHasChanged = !compareDeep(
      backendSearchRequest,
      clientSearchRequest
    );

    if (searchRequestHasChanged) {
      // if there is a change in the search, enable srp view tracking
      if (!newSrpView) setNewSrpView(true);
    }

    const searchIsStableForTracking =
      newSrpView &&
      !isDebounceActive &&
      !isFetching &&
      tracking &&
      !searchRequestHasChanged; // search is settled, after updates

    if (!searchIsStableForTracking) return;

    const tryRefreshAds = (
      dataLayer: EcgdkTrackingDataLayerDtoDataLayerDto | undefined,
      shouldUpdateDataLayer = true
    ) => {
      const dataLayerHasChanged = !compareDeep(
        lastDataLayerForAdsRefresh.current,
        dataLayer,
        { matchAnyCasingForStrings: true, trimStrings: true }
      );

      if (!dataLayerHasChanged) return;

      lastDataLayerForAdsRefresh.current = dataLayer;

      if (shouldUpdateDataLayer) {
        updateDataLayer({ dataLayer });
      }

      Ads.Utilities.refresh();
    };

    if (!canTrackSrpView) {
      // Request ads refresh on debounce end, if the search request has changed but
      // it doesn't qualify as an actual trackable page view.

      const previousKeyword = lastDataLayerForAdsRefresh.current?.s?.kw ?? '';
      const currentKeyword = tracking.dataLayer?.s?.kw ?? '';

      const keywordHasChanged = !compareDeep(previousKeyword, currentKeyword, {
        matchAnyCasingForStrings: true,
        trimStrings: true,
      });

      if (keywordHasChanged) {
        tryRefreshAds(tracking.dataLayer);
      }

      return;
    }

    updateDataLayer(tracking);
    onNewPageView?.();
    tryRefreshAds(tracking.dataLayer, false);

    const pulsePageObject = getPulsePageObject();

    if (pulsePageObject) {
      trackPulsePageViewEvent(
        pulsePageObject,
        window,
        experimentVariants && pulseExperimentObject(experimentVariants)
      );
    }

    // disable view tracking until specifically enabled again both from a change in search
    // and from components: fx. when the user clicks on a chip, clicks on free text search suggestion etc.
    setNewSrpView(false);
    setCanTrackSrpView(false);

    // Disabled, as we do not want to run this when the clientSearchRequest changes (e.g. a UI interaction - only when the backendSearchRequest changes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newSrpView,
    canTrackSrpView,
    tracking,
    isDebounceActive,
    isFetching,
    backendSearchRequest,
  ]);

  return {
    newSrpView,
    toggleSrpViewTracking,
  };
};
