import { GalleryViewIcon } from '@/icons/GallerViewIcon';
import { ListViewIcon } from '@/icons/ListViewIcon';
import cx from 'classnames';
import styles from './DisplayModeSelect.module.scss';
import { Tooltip } from './Tooltip';

export type DisplayMode = 'Gallery' | 'List' | 'Map';

type Props = {
  mode: DisplayMode;
  onChange: (mode: DisplayMode) => void;
};

export const DisplayModeSelect = ({ mode, onChange }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Tooltip content="Gallerivisning" placement="top">
        <button onClick={() => onChange('Gallery')} title="Gallerivisning">
          <GalleryViewIcon
            className={cx(styles.option, {
              [styles.active]: mode === 'Gallery',
            })}
            aria-hidden="true"
            focusable="false"
          />
          <span className="visually-hidden">Gallerivisning</span>
        </button>
      </Tooltip>
      <Tooltip content="Listevisning" placement="top">
        <button onClick={() => onChange('List')} title="Listevisning">
          <ListViewIcon
            className={cx(styles.option, { [styles.active]: mode === 'List' })}
            aria-hidden="true"
            focusable="false"
          />
          <span className="visually-hidden">Listevisning</span>
        </button>
      </Tooltip>
    </div>
  );
};
