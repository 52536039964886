/* eslint-disable @next/next/no-img-element */
import { formatDKK } from '@/utils';
import Carousel from '@/molecules/Carousel';
import { CarouselIndicator } from '@/atoms/CarouselIndicator';
import { ImageWithFallback } from '@/atoms/ImageWithFallback';
import styles from './RelatedListing.module.scss';
import React from 'react';
import {
  SmdBasSrpModelsPriceTypeLabel,
  SmdBasSrpWebApiModelsRelatedListingWebModel,
} from 'types/api';
import ThreeSixtyImageIcon from '@/icons/ThreeSixtyImageIcon';
import VideoIcon from '@/icons/VideoIcon';
import cx from 'classnames';
import { trackPulseRecommendationItemClickEvent } from '@smd/tracking';

interface Props {
  data: SmdBasSrpWebApiModelsRelatedListingWebModel;
  rank?: number;
}

const formatPrice = (
  price: number,
  priceType: SmdBasSrpModelsPriceTypeLabel
) => {
  if (priceType !== 'CallForPrice' && typeof price !== 'number') {
    return 'Ukendt';
  }

  switch (priceType) {
    case 'CallForPrice':
      return <span>Ring for pris</span>;
    case 'LeasingInclVat':
      return <span>{`${formatDKK(price)}/md`}</span>;
    case 'LeasingExclVat':
      return (
        <>
          <span>{`${formatDKK(price)}/md`}</span>
          <span className="meta"> (ex. moms)</span>
        </>
      );
    case 'WithoutTax':
      return (
        <>
          <span>{`${formatDKK(price)}`}</span>
          <span className="meta"> (Uden afgift)</span>
        </>
      );
    case 'Wholesale':
      return (
        <>
          <span>{`${formatDKK(price)}`}</span>
          <span className="meta"> (Engros/CVR)</span>
        </>
      );
    default:
      return <span>{`${formatDKK(price)}`}</span>;
  }
};

const Price = ({
  price,
  priceType,
}: {
  price: number;
  priceType: SmdBasSrpModelsPriceTypeLabel;
}) => (
  <span className="font-bold color-primary">
    {formatPrice(price, priceType)}
  </span>
);

export const RelatedListing = React.memo(({ data, rank }: Props) => {
  const {
    externalId,
    uri,
    make,
    model,
    variant,
    isKlikXtra,
    isExtraPromoted,
    isPlusAd,
  } = data;
  const { price, priceType } = data.price!;

  const images =
    data.media
      ?.filter((m) => m.mediaType === 'Picture')
      .map((p) => p.url ?? '') ?? [];

  const has360image = data.media?.some(
    (m) => m.mediaType === 'ThreeSixtyPicture'
  );
  const hasVideo = data.media?.some((m) => m.mediaType === 'Video');

  if (!uri) return null;

  const altText = [make, model, variant].filter(Boolean).join(' ');

  const handleClick = () => {
    if (typeof externalId !== 'undefined') {
      trackPulseRecommendationItemClickEvent({
        classifiedAdId: `${externalId}`,
        elementName: 'ClassifiedAdItem',
        eventDescription: isKlikXtra
          ? 'Klikxtra'
          : isExtraPromoted
          ? 'PrivateRelated'
          : isPlusAd
          ? 'PlusAd'
          : 'RelatedListing',
        elementPosition: rank,
      });
    }
  };

  return (
    <article className={cx(styles.listing)}>
      {/* The anchor below is covering the entire article - even though it appears to be empty */}
      <a href={uri} className={styles.link} onClick={handleClick} />
      <div className={styles.listingWrap}>
        <div className={styles.media}>
          <Carousel
            className={styles.image}
            overlay={(index: number) => (
              <>
                {hasVideo || has360image ? (
                  <div className={styles.mediaIcons}>
                    {has360image ? (
                      <ThreeSixtyImageIcon height={18} width={18} />
                    ) : null}
                    {hasVideo ? <VideoIcon height={18} width={18} /> : null}
                  </div>
                ) : null}
                <CarouselIndicator
                  className={styles.dots}
                  length={images?.length ?? 0}
                  index={index}
                />
              </>
            )}
          >
            {images && images.length > 0 ? (
              images.map((image, index) => (
                <a
                  key={index}
                  href={uri}
                  aria-hidden="true"
                  tabIndex={-1}
                  className={styles.imageLink}
                  onClick={handleClick}
                >
                  <ImageWithFallback
                    src={image}
                    sizes="(max-width: 500px) 100vw, (max-width: 800px) 50vw, 33vw"
                    fill
                    alt={altText}
                    className="object-fit-cover"
                  />
                </a>
              ))
            ) : (
              <a
                href={uri}
                aria-hidden="true"
                tabIndex={-1}
                onClick={handleClick}
              >
                <ImageWithFallback
                  alt="picture"
                  fill
                  className="object-fit-cover"
                />
              </a>
            )}
          </Carousel>
        </div>
        <div className={styles.makeModel}>
          <div>
            <h3 className="font-bold">{make + ' ' + model}</h3>
            <span className="caption">{variant}</span>
          </div>
        </div>
        <div className={styles.price}>
          <Price price={price!} priceType={priceType!} />
        </div>
      </div>
    </article>
  );
});

RelatedListing.displayName = 'RelatedListing';
