import Chip from '@/atoms/Chip';
import React from 'react';
import styles from './Chips.module.scss';
import cx from 'classnames';
import {
  SmdBasSrpModelsSelectedFilter,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';
import Button from '@/atoms/Button';
import { trackPulseUIElementEvent } from '@smd/tracking';
import { useSrpView } from '@/hooks/useSrpView';

type Props = {
  selectedFilters?: Record<string, SmdBasSrpModelsSelectedFilter>;
  getFiltersKeys?: () => string[];
  getValue: (
    key: string
  ) => string | number | SmdBasSrpModelsSelectedFilterValue;
  getLabelValue: (key: string, value: string | number) => string;
  getFiltersOptionValues: (key: string) => (string | null | undefined)[];
  toggleValue: (
    key: string,
    value: string | number | SmdBasSrpModelsSelectedFilterValue
  ) => void;
  clear: (
    key?: string,
    value?: string | number | SmdBasSrpModelsSelectedFilterValue
  ) => void;
  canBeEmpty: (
    key: string,
    value?: string | number | SmdBasSrpModelsSelectedFilterValue
  ) => boolean;
};

type Chip = {
  label: string;
  key: string;
  value: string | number;
  onRemove?: () => void;
  children?: Chip[];
};

type NestedChip = { [k: string]: Chip | NestedChip | NestedChip[] };

export const SrpChips = ({
  selectedFilters,
  getFiltersKeys,
  getValue,
  getLabelValue,
  getFiltersOptionValues,
  toggleValue,
  clear,
  canBeEmpty,
}: Props) => {
  const { toggleSrpViewTracking } = useSrpView();

  if (!selectedFilters) return null;

  const makeChips = getChips(
    selectedFilters,
    getFiltersKeys,
    getValue,
    getLabelValue,
    getFiltersOptionValues,
    clear,
    toggleValue,
    canBeEmpty,
    toggleSrpViewTracking
  );
  const chips = [...(makeChips ?? [])];

  if (chips.filter(Boolean).length === 0) return null;

  const handleButtonClick = () => {
    toggleSrpViewTracking(true); // always trigger view tracking when clicking Nulstil on chips, since it automatically means a change in the search request
    clear();
  };

  return (
    <div className={cx(styles.srpChips, styles.showAllChips)} data-e2e="chips">
      <Button
        style={{
          textDecoration: 'underline',
          color: 'var(--color-primary)',
          fontWeight: 500,
          backgroundColor: 'transparent',
          outline: 'none',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        as="a"
        onClick={handleButtonClick}
        data-e2e="reset-filters"
      >
        Nulstil
      </Button>
      {chips.map((chip, index: number) => (
        <Chip key={index} content={chip.label} onRemove={chip.onRemove}>
          {chip.children?.map((childChip: Chip) => (
            <Chip
              key={chip.label + childChip.label}
              content={childChip.label}
              onRemove={childChip.onRemove}
            />
          ))}
        </Chip>
      ))}
    </div>
  );
};

const findAllObjectValues = (arr: NestedChip[], result: Chip[] = []) => {
  for (const item of arr) {
    for (const key in item) {
      const value = item[key];
      if (Array.isArray(value)) {
        findAllObjectValues(value, result);
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        result.push(value as Chip);
      }
    }
  }
  return result;
};

const createChip = (
  key: string,
  value: string | number | SmdBasSrpModelsSelectedFilterValue,
  getLabelValue: Props['getLabelValue'],
  canBeEmpty: Props['canBeEmpty'],
  clear: Props['clear'],
  toggleSrpViewTracking: (canTrack: boolean) => void,
  chipChildren: Chip[]
): Chip | undefined => {
  const chipValue =
    (value as SmdBasSrpModelsSelectedFilterValue).value ??
    (value as string | number);
  const labelValue = getLabelValue(key, chipValue);
  if (!labelValue) return undefined;
  const isChipRemovable = canBeEmpty(key, chipValue);
  return {
    label: labelValue,
    key,
    value: chipValue,
    onRemove: isChipRemovable
      ? () => {
          trackPulseUIElementEvent({
            elementName: `RemoveSrpChip${key}`,
            eventDescription: labelValue,
          });
          toggleSrpViewTracking(true); // always trigger view tracking when removing a chip, since it automatically means a change in the search request
          clear(key, chipValue);
        }
      : undefined,
    children: chipChildren,
  };
};

// The chips should have the same order as the filterOptions;
// Since the chips are actually the selectedFilters that are ordered on the backend, we need to also reorder the chips on the frontend
// to not have a flicker & a sudden change of order, when the chips are reordered after the backend response
const getChips = (
  selectedFilters: Props['selectedFilters'],
  getFiltersKeys: Props['getFiltersKeys'],
  getValue: Props['getValue'],
  getLabelValue: Props['getLabelValue'],
  getFiltersOptionValues: Props['getFiltersOptionValues'],
  clear: Props['clear'],
  toggleValue: Props['toggleValue'],
  canBeEmpty: Props['canBeEmpty'],
  toggleSrpViewTracking: (canTrack: boolean) => void
) => {
  /* the chips can have a structure such as:
    [
      {
        'filterKey1': Chip // regular, single value chip
      },
      {
        'filerKey2': [{'filterOptionValue1': Chip1}, {'filterOptionValue2': Chip2] // multiple chips for the same filter key
      },
    ]
    In case of parent-child chips, the Chip will have a children property which will be an array of Chip(s) - the case for the Make & Model chips
  */
  let chips: NestedChip[] = [];
  const filtersKeys = getFiltersKeys?.() ?? []; // the keys of the filterOptions, in the order shown from the backend

  for (const key in selectedFilters) {
    if (key === 'FreeText') continue; // FreeText value visible already on SRP in the input field, so skip it;

    if (filtersKeys.includes(key)) {
      const selectedValue = getValue(key);

      // multiple values selected for the same filter key
      if (Array.isArray(selectedValue)) {
        const filterOptionValues = getFiltersOptionValues(key);
        let multipleChips: NestedChip[] = [];

        // create a chip for each valid value
        (selectedValue as SmdBasSrpModelsSelectedFilterValue[])
          .filter(Boolean)
          .map((value) => {
            // the Model chips case
            if (value.parent && value.values) {
              let childChips: NestedChip[] = [];
              value.values.filter(Boolean).map((childValue) => {
                const chip = createChip(
                  key,
                  childValue,
                  getLabelValue,
                  canBeEmpty,
                  (chipKey, childValue) => {
                    toggleValue(chipKey!, {
                      parent: {
                        key: value.parent!.key,
                        value: value.parent!.value,
                      },
                      value: childValue as string,
                    });
                  },
                  toggleSrpViewTracking,
                  []
                );
                if (chip) {
                  childChips.push({ [childValue]: chip }); // a child chip for each selected child value
                }
              });
              // sort the Model chips by the order of the filterOptionValues that the Model key has, as they come from the backend
              childChips.sort((a: NestedChip, b: NestedChip) => {
                return (
                  filterOptionValues.indexOf(Object.keys(a)[0]) -
                  filterOptionValues.indexOf(Object.keys(b)[0])
                );
              });
              // in the already created chips array, find the chip that is the parent of the Model chip a.k.a the chip element with key Make
              const parentChip = chips.find(
                (chip) => Object.keys(chip)[0] === value.parent!.key
              );

              if (parentChip) {
                // the Make chip in the chips array, has its own array of { [filterOptionValue]: Chip } elements, fx: [{ 'Audi': Chip1 }, { 'BMW': Chip2 }], where Chip1 & Chip2 have a children property
                const nestedParentValues = parentChip[
                  value.parent!.key! as keyof typeof parentChip
                ] as NestedChip[];

                // find the chip element in the Make array chips, that has the same key as the parent value of the selected Model (fx. 'Audi')
                const nestedParentValue = nestedParentValues.find(
                  (chip) => Object.keys(chip)[0] === value.parent!.value
                );
                if (nestedParentValue) {
                  const parentValueChip = nestedParentValue[
                    value.parent!.value! as keyof typeof nestedParentValue
                  ] as Chip;
                  // add the selected Model chips as children of the corresponding chip element in the Make array chips
                  // to create a structure such as:
                  /*
                    [
                      {
                        'Make': [{'Audi': {label: 'Audi', key: 'Audi', children: [{label: 'A4', key: 'Model', value: 'A4', onRemove: () => ..., children: []}]}}]                      
                      }
                    ]
                  */
                  parentValueChip.children = findAllObjectValues(childChips);
                }
              }
            } else {
              const chip = createChip(
                key,
                value,
                getLabelValue,
                canBeEmpty,
                clear,
                toggleSrpViewTracking,
                []
              );
              if (chip) {
                multipleChips.push({ [`${value.value ?? value}`]: chip }); // a chip for each selected value
              }
            }
          });
        multipleChips.sort((a: NestedChip, b: NestedChip) => {
          return (
            filterOptionValues.indexOf(Object.keys(a)[0]) -
            filterOptionValues.indexOf(Object.keys(b)[0])
          );
        });
        chips.push({ [`${key}`]: multipleChips });
      } else {
        // single value selected for the filter key
        if (!selectedValue) continue;
        const chip = createChip(
          key,
          selectedValue,
          getLabelValue,
          canBeEmpty,
          clear,
          toggleSrpViewTracking,
          []
        );
        if (chip) {
          chips.push({ [`${key}`]: chip }); // a chip for each key
        }
      }
    }
  }
  chips.sort((a: NestedChip, b: NestedChip) => {
    return (
      filtersKeys.indexOf(Object.keys(a)[0]) -
      filtersKeys.indexOf(Object.keys(b)[0])
    );
  });
  return findAllObjectValues(chips);
};
