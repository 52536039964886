import {
    Dialog,
    DialogBody,
    DialogContent,
} from '@/atoms/Dialog';
import React, {useEffect, useRef} from 'react';
import styles from './Alert.module.scss';
import {Level, Toast} from "@/atoms/Toast";

interface AlertProps {
    open: boolean;
    setOpen: ((value: boolean) => void);
    text: string;
    level: Level;
    duration?: number;
}

export const Alert = ({ open, setOpen, text, duration = 2000, level }: AlertProps) => {
    const alertTimerId = useRef<number | null>(null);

    useEffect(() => {
        if (open) {
            alertTimerId.current = window.setTimeout(() => {
                setOpen(false);
            }, duration);
        }
        return () => {
            if (alertTimerId.current) {
                clearTimeout(alertTimerId.current);
            }
        };
    })

    return (
        <Dialog open={open} onOpenChange={setOpen} className={styles.overlay}>
            <DialogContent className={styles.container} lockScroll={false}>
                <DialogBody className={styles.content}>
                    <Toast
                        className={styles.alert}
                        level={level}
                        text={text}
                    />
                </DialogBody>
            </DialogContent>
        </Dialog>
    );
};

export default Alert;
