import {
  flip,
  FloatingFocusManager,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import cx from 'classnames';
import SortCarret from '@/icons/SortCarret';
import styles from './ListingHeader.module.scss';
import {
  SmdBasSrpModelsSelectedSorting,
  SmdBasSrpModelsSortOption,
} from 'types/api';
import React from 'react';

export const customColumns = [
  'moth',
  'kml',
  'hk',
  'kmt',
  'trailer',
  'electricmotorrange',
  'batterycapacity',
];

type SortableHeaderProps = {
  sortKey: string;
  title: string;
  onChange?: (sortBy: string, sortOrder: string) => void;
  currentSort?: SmdBasSrpModelsSelectedSorting;
};

const SortableHeader = ({
  sortKey,
  title,
  onChange,
  currentSort,
}: SortableHeaderProps) => {
  const active = currentSort?.sortBy === sortKey;

  const handleSort = () =>
    onChange?.(
      sortKey,
      active && currentSort?.sortOrder === 'desc' ? 'asc' : 'desc'
    );

  return (
    <button
      className={cx(styles.sortableHeader, { [styles.active]: active })}
      onClick={handleSort}
    >
      {title}
      <SortCarret
        asc={active && currentSort?.sortOrder === 'asc'}
        desc={active && currentSort?.sortOrder === 'desc'}
      />
    </button>
  );
};

type CustomSortableHeaderProps = {
  currentColumnKey: string;
  onChangeSort?: (sortBy: string, sortOrder: string) => void;
  currentSort?: SmdBasSrpModelsSelectedSorting;
  sortTitles?: Record<string, string>;
};

const CustomSortableHeader = ({
  currentColumnKey,
  onChangeSort,
  currentSort,
  sortTitles,
}: CustomSortableHeaderProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [
      offset({ mainAxis: 4, crossAxis: 4 }),
      size({
        padding: 16,
      }),
      flip(),
      shift(),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, dismiss, role]
  );

  const handleSort = () =>
    onChangeSort?.(
      currentColumnKey,
      active && currentSort?.sortOrder === 'desc' ? 'asc' : 'desc'
    );

  const active = currentColumnKey === currentSort?.sortBy;

  return (
    <div className={styles.sortableHeader}>
      <button
        className={cx(styles.customColumn, { [styles.active]: active })}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {sortTitles?.[currentColumnKey] ?? currentColumnKey}
      </button>
      <button
        onClick={handleSort}
        className={cx(styles.carret, { [styles.active]: active })}
      >
        <SortCarret
          asc={active && currentSort?.sortOrder === 'asc'}
          desc={active && currentSort?.sortOrder === 'desc'}
        />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false} initialFocus={0}>
          <div
            ref={refs.setFloating}
            className={styles.popover}
            style={{
              ...floatingStyles,
            }}
            {...getFloatingProps()}
          >
            {customColumns?.map((col, index) => (
              <button
                key={col}
                {...getItemProps({
                  // ref(node: HTMLButtonElement) {
                  //   listItemsRef.current[index] = node;
                  // },
                  // tabIndex: activeIndex === index ? 0 : -1,
                  // onMouseEnter: () => {
                  //   if (allowHover && isOpen) {
                  //     setActiveIndex(index);
                  //   }
                  // },
                  onClick: () => {
                    onChangeSort?.(col, currentSort?.sortOrder ?? 'asc');
                    setIsOpen(false);
                  },
                  className: cx(styles.item, {
                    [styles.active]: currentSort?.sortBy === col,
                  }),
                  role: 'menuitem',
                })}
              >
                {sortTitles?.[col] ?? col}
              </button>
            ))}
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
};

type ListingHeaderProps = {
  customColumnKey?: string;
  currentSort?: SmdBasSrpModelsSelectedSorting;
  sortOptions?: SmdBasSrpModelsSortOption[];
  onChangeSort?: (sortBy: string, sortOrder: string) => void;
};

export const ListingHeader = ({
  customColumnKey = 'kml',
  currentSort,
  sortOptions,
  onChangeSort,
}: ListingHeaderProps) => {
  const titles =
    sortOptions?.reduce((acc, option) => {
      acc[option.sortBy] = option.name;
      return acc;
    }, {} as Record<string, string>) ?? {};

  return (
    <div className={styles.header}>
      <SortableHeader
        sortKey="date"
        title={titles['date'] ?? 'Dato'}
        currentSort={currentSort}
        onChange={onChangeSort}
      />
      <SortableHeader
        sortKey="make"
        title={titles['make'] ?? 'Mærke'}
        currentSort={currentSort}
        onChange={onChangeSort}
      />
      <SortableHeader
        sortKey="firstregistrationdate"
        title={titles['firstregistrationdate'] ?? '1. reg.'}
        currentSort={currentSort}
        onChange={onChangeSort}
      />
      <SortableHeader
        sortKey="mileage"
        title={titles['mileage'] ?? 'Km'}
        currentSort={currentSort}
        onChange={onChangeSort}
      />
      <CustomSortableHeader
        currentColumnKey={customColumnKey}
        currentSort={currentSort}
        onChangeSort={onChangeSort}
        sortTitles={titles}
      />
      <SortableHeader
        sortKey="price"
        title={titles['price'] ?? 'Pris'}
        currentSort={currentSort}
        onChange={onChangeSort}
      />
    </div>
  );
};
