import { BreadcrumbList } from 'schema-dts';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import React from 'react';
import { SmdBasSrpModelsBreadcrumb } from 'types/api';

interface IBreadCrumbs {
  crumbs: Array<SmdBasSrpModelsBreadcrumb>;
}

export const SEOBreadcrumbs = ({ crumbs }: IBreadCrumbs) => {
  return (
    <Head>
      <script
        {...jsonLdScriptProps<BreadcrumbList>({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: crumbs.map((crumb, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: crumb.title ?? undefined,
            item: crumb.link ?? undefined,
          })),
        })}
      />
    </Head>
  );
};

export default SEOBreadcrumbs;
