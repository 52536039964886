import React from 'react';

export const LocationIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={17}
    width={16}
    viewBox="0 0 16 17"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.398 7.25c0-3.272 2.503-5.926 5.6-5.95 3.097.024 5.6 2.678 5.6 5.95 0 1.603-.65 3.152-1.604 4.235L8 15.7l-4.01-4.23c-.947-1.083-1.592-2.625-1.592-4.22Zm9.956 0c0-2.535-1.953-4.613-4.355-4.637-2.403.024-4.356 2.102-4.356 4.637 0 1.174.465 2.41 1.246 3.31L8 13.844l3.098-3.27a5.19 5.19 0 0 0 1.257-3.323Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.848 7.209c0-1.27.975-2.298 2.177-2.298 1.203 0 2.178 1.029 2.178 2.298 0 1.269-.975 2.298-2.178 2.298-1.202 0-2.177-1.03-2.177-2.298Zm3.11 0c0-.543-.418-.985-.932-.985-.515 0-.934.442-.934.985 0 .543.419.985.934.985.514 0 .933-.442.933-.985Z"
    />
  </svg>
));

LocationIcon.displayName = 'LocationIcon';
export default LocationIcon;
