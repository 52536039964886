import { ItemList } from 'schema-dts';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import React from 'react';
import {
  SmdBasSrpModelsMedia,
  SmdBasSrpWebApiModelsWebListing,
} from 'types/api';
import fallbackImg from '../../public/missing.svg';

type Props = {
  listings: Array<SmdBasSrpWebApiModelsWebListing>;
  hits?: number;
};

const getImages = (media: SmdBasSrpModelsMedia[] | undefined | null) => {
  const pictures = media?.filter((m) => m.mediaType === 'Picture') ?? [];

  if (pictures.length === 0) return [fallbackImg.src];

  return pictures.map((m) => m.url ?? '');
};

export const SEOListings = ({ listings, hits }: Props) => {
  return (
    <Head>
      <script
        {...jsonLdScriptProps<ItemList>({
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          numberOfItems: hits ?? listings.length,
          itemListElement: listings.map((listing, index) => ({
            '@type': 'Product',
            image: getImages(listing.media),
            position: `${index + 1}`,
            name:
              [listing.make, listing.model, listing.variant].join(' ') ??
              undefined,
            url: listing.uri ?? undefined,
            offers: {
              '@type': 'Offer',
              price: listing.price?.price ?? undefined,
              priceCurrency: 'DKK',
            },
          })),
        })}
      />
    </Head>
  );
};

export default SEOListings;
