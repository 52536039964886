export const FilterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={16}
    width={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M1 3h8M7 13h8M13 3h2M1 13h2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="11" cy="3" r="2" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="5" cy="13" r="2" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

FilterIcon.displayName = 'FilterIcon';
export default FilterIcon;
