import React from 'react';
import * as Ads from '@smd/advertising';
import type { SmdBasSrpWebApiModelsWebListing } from 'types/api';

export const useIntermingleAds = (
  listings: Array<SmdBasSrpWebApiModelsWebListing>
) => {
  const { intermingleItems, isSuccess, columnsCount } =
    Ads.Core.Config.Context.use();

  return React.useMemo(() => {
    if (!isSuccess || !intermingleItems.length) return listings;

    return Array.from(
      Ads.intermingle({
        listings,
        intermingle: {
          ads: intermingleItems,
        },
        columnsCount,
      })
    );
  }, [listings, isSuccess, intermingleItems, columnsCount]);
};
