export const ListViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={20}
    width={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M16.933 2H3.067C2.477 2 2 2.478 2 3.067v4.266c0 .59.478 1.067 1.067 1.067h13.866c.59 0 1.067-.478 1.067-1.067V3.067C18 2.477 17.522 2 16.933 2ZM16.933 11.6H3.067c-.59 0-1.067.478-1.067 1.067v4.266C2 17.523 2.478 18 3.067 18h13.866c.59 0 1.067-.478 1.067-1.067v-4.266c0-.59-.478-1.067-1.067-1.067Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
