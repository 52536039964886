import styles from './FiltersCaption.module.scss';

type Props = {
  caption: string;
  shortCaption: string;
};

export const FiltersCaption = ({ caption, shortCaption }: Props) => {
  return (
    <>
      <span className={styles.caption}>{caption}</span>
      <span className={styles.shortCaption}>{shortCaption}</span>
    </>
  );
};

export default FiltersCaption;
