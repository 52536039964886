import classNames from 'classnames';
import React from 'react';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import SmallCloseIcon from '@/icons/SmallCloseIcon';
import styles from './Chip.module.scss';

type Props = {
  content: React.ReactNode;
  icon?: React.ReactNode;
  onRemove?: () => void;
  onClick?: () => void;
  active?: boolean;
  children?: React.ReactNode;
};

export const Chip = React.forwardRef<HTMLDivElement, Props>(
  ({ content, icon, active = false, onClick, onRemove, children }, ref) => {
    return React.Children.count(children) > 0 ? (
      <div className={styles.chipWrap} ref={ref}>
        <div className={styles.chipParent} onClick={onClick}>
          {icon}
          <span className="caption font-medium">{content}</span>
        </div>
        {children}
        {!!onRemove && (
          <button onClick={onRemove} className={styles.chipWrapRemove}>
            <LargeCloseIcon />
          </button>
        )}
      </div>
    ) : (
      <div
        className={classNames(styles.chip, { [styles.active]: active })}
        onClick={onClick}
        ref={ref}
      >
        {icon}
        <span className="caption font-medium">{content}</span>
        {!!onRemove && (
          <button onClick={onRemove} className={styles.remove}>
            <SmallCloseIcon />
          </button>
        )}
      </div>
    );
  }
);

Chip.displayName = 'Chip';

export default Chip;
