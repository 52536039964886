import Chip from '@/atoms/Chip';
import React from 'react';
import { useMeasure } from 'react-use';
import styles from './Chips.module.scss';
import cx from 'classnames';
import {
  SmdBasSrpModelsSelectedFilter,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';

type Props = {
  selectedFilters?: Record<string, SmdBasSrpModelsSelectedFilter>;
  getValue: (key: string) => any;
  getLabelValue: (key: string, value: string | number) => string;
  toggleValue: (key: string, value: any) => void;
};

export const Chips = ({
  selectedFilters,
  getValue,
  getLabelValue,
  toggleValue,
}: Props) => {
  const [showAll, setShowAll] = React.useState(false);
  const [chipContainerRef, { width: chipContainerWidth }] =
    useMeasure<HTMLDivElement>();
  const chipRefs = React.useRef<Array<HTMLDivElement | null>>([]);

  if (!selectedFilters) return null;

  const makeModelChips = getMakeModelChips(
    getValue,
    getLabelValue,
    toggleValue
  );

  const chips = [...makeModelChips];

  if (chips.filter(Boolean).length === 0) return null;

  const chipWidth =
    chipRefs.current
      .filter(Boolean)
      .reduce((acc, ref) => acc + (ref?.clientWidth ?? 0), 0) +
    chipRefs.current.filter(Boolean).length * 8;

  const toggleShowAll = () => setShowAll((prev) => !prev);

  return (
    <div
      className={cx(styles.chips, { [styles.showAllChips]: showAll })}
      ref={chipContainerRef}
    >
      {chips.map((chip, index: number) => (
        <Chip
          key={index}
          content={chip.label}
          onRemove={chip.onRemove}
          ref={(node: HTMLDivElement) => {
            chipRefs.current[index] = node;
          }}
        >
          {chip.children?.map((childChip) => (
            <Chip
              key={chip.label + childChip.label}
              content={childChip.label}
              onRemove={childChip.onRemove}
            />
          ))}
        </Chip>
      ))}
      {chipWidth > chipContainerWidth && !showAll ? (
        <button className={styles.showAllChipsButton} onClick={toggleShowAll}>
          Vis alle
        </button>
      ) : null}
    </div>
  );
};

const getMakeModelChips = (
  getValue: Props['getValue'],
  getLabelValue: Props['getLabelValue'],
  toggleValue: Props['toggleValue']
) => {
  const selectedModels = (getValue('Model') ??
    []) as SmdBasSrpModelsSelectedFilterValue[];
  const selectedMakes = (getValue('Make') ??
    []) as SmdBasSrpModelsSelectedFilterValue[];

  const makeModelChips = selectedMakes?.map((make) => {
    const models = selectedModels.find(
      (model) => model.parent?.value === make.value
    );

    return {
      label: getLabelValue('Make', make.value ?? ''),
      key: 'Make',
      value: make.value,
      onRemove: () => toggleValue('Make', make.value),
      children: models?.values?.map((model) => ({
        label: getLabelValue('Model', model ?? ''),
        key: 'Model',
        value: model,
        onRemove: () =>
          toggleValue('Model', {
            parent: {
              key: 'Make',
              value: make.value,
            },
            value: model,
          }),
      })),
    };
  });

  return makeModelChips;
};
