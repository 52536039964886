export const ThreeSixtyImageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12 18.35c-6.1 0-11-1.8-11-4 0-.9.7-1.6 2-2.3M22 12.75c.6.5 1 1.1 1 1.7 0 1.6-2.4 2.9-6 3.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10 15.35 3 3-3 3"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 4a2.25 2.25 0 0 0-2.25 2.25V8.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.75 8.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM5.5 5.112A1.5 1.5 0 1 1 6.95 7a1.5 1.5 0 1 1-1.446 1.9M19 8.5a1.5 1.5 0 1 1-3 0v-3a1.5 1.5 0 1 1 3 0v3Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ThreeSixtyImageIcon.displayName = 'ThreeSixtyImageIcon';
export default ThreeSixtyImageIcon;
