export const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="68"
    height="64"
    viewBox="0 0 68 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.3501 63.4293C60.3337 59.6079 73.1865 36.1334 64.2145 16.6923C50.848 -12.2804 1.08467 -1.19586 0.0148779 31.9509C-0.649718 52.5364 21.0751 67.1134 40.3501 63.4293ZM8.3986 16.5634C20.4241 0.0519496 48.41 -1.92997 59.286 14.2407C69.9754 30.1486 61.49 53.4248 43.3814 59.4349C17.9132 67.8933 -9.00465 40.4584 8.3986 16.5634Z"
      fill="#FF4B00"
    />
    <path
      d="M16.5987 31.2928C22.0172 35.5424 27.1945 40.0909 32.1065 44.917C32.2502 45.063 32.4213 45.1792 32.6101 45.2588C32.7989 45.3384 33.0015 45.3799 33.2064 45.3808C33.4112 45.3818 33.6143 45.3422 33.8038 45.2643C33.9933 45.1865 34.1655 45.0719 34.3105 44.9272C34.9276 44.3151 43.1266 33.7935 53.2379 22.6259C55.5589 20.0608 55.2588 16.6259 48.0687 22.6259C43.7996 26.1862 35.5346 34.4937 31.8522 38.4457C30.2124 36.8615 28.4555 35.4033 26.5965 34.0834C14.2218 25.4861 13.9352 29.0277 16.5987 31.2928Z"
      fill="#FF4B00"
    />
  </svg>
);

CheckmarkIcon.displayName = 'CheckmarkIcon';
export default CheckmarkIcon;
