export const BellIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.58496 15.9567C6.77588 16.5693 7.35897 17 8.0021 17C8.64523 17 9.22833 16.5693 9.41924 15.9567"
      stroke="#FF4B00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 2.60006V1"
      stroke="#FF4B00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99986 2.60004C10.964 2.60004 13.3334 5.08302 13.3334 8.01824C13.3334 13.1085 14.4001 13.9783 14.4001 13.9783H1.59961C1.59961 13.9783 2.66632 12.5941 2.66632 8.01824C2.66632 5.1031 5.05936 2.60004 7.99986 2.60004Z"
      stroke="#FF4B00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

BellIcon.displayName = 'BellIcon';
export default BellIcon;
