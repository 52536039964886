import {
  flip,
  FloatingFocusManager,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import React from 'react';
import cx from 'classnames';
import Button from '@/atoms/Button';
import ChevronDown from '@/icons/ChevronDown';
import styles from './Sorting.module.scss';
import {
  SmdBasSrpModelsSelectedSorting,
  SmdBasSrpModelsSortOption,
} from 'types/api';
import Alert from '@/molecules/Alert';
import { Level } from '@/atoms/Toast';

export const SortingItem = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
  }
>(({ disabled, ...props }, ref) => {
  return (
    <button
      ref={ref}
      role="menuitem"
      disabled={disabled}
      {...props}
      className={styles.item}
    />
  );
});

SortingItem.displayName = 'SortingItem';

type Props = {
  current?: SmdBasSrpModelsSelectedSorting;
  options?: SmdBasSrpModelsSortOption[];
  onChange?: (sortBy: string, sortOrder: string) => void; // TODO: sortOrder should be an enum - also in the API
  sortAlert?: boolean;
  setSortAlert?: (value: boolean) => void;
};

export const Sorting = ({ options, onChange, current, sortAlert, setSortAlert }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [allowHover, setAllowHover] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const listItemsRef = React.useRef<Array<HTMLButtonElement | null>>([]);

  const { x, y, refs, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [
      offset({ mainAxis: 4, crossAxis: 4 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, 800)}px`,
          });
        },
        padding: 16,
      }),
      flip(),
      shift(),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });
  const listNavigation = useListNavigation(context, {
    listRef: listItemsRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, dismiss, role, listNavigation]
  );

  React.useEffect(() => {
    function onPointerMove({ pointerType }: PointerEvent) {
      if (pointerType !== 'touch') {
        setAllowHover(true);
      }
    }

    function onKeyDown() {
      setAllowHover(false);
    }

    window.addEventListener('pointermove', onPointerMove, {
      once: true,
      capture: true,
    });
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('pointermove', onPointerMove, {
        capture: true,
      });
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [allowHover]);

  const mergedOptions = options?.flatMap((option) =>
    (option.sortOrders?.length ?? 0) > 0
      ? option.sortOrders?.map((sortOrder) => ({
          label: `${option.name}${
            sortOrder.name.length > 0 ? `: ${sortOrder.name}` : ''
          }`,
          key: option.name + sortOrder.name,
          sortBy: option.sortBy,
          sortOrder: sortOrder.order,
        }))
      : {
          label: option.name,
          key: option.name,
          sortBy: option.sortBy,
          sortOrder: '',
        }
  );

  const selectedOption = mergedOptions?.find((o) => {
    if (!!current && current.sortBy === 'relevance') {
      return o?.sortBy === '';
    }
    return !!current
      ? o?.sortBy === current?.sortBy && o?.sortOrder === current?.sortOrder
      : o?.sortBy === '';
  });

  return (
    <>
      <div className={styles.sorting}>
        {!!setSortAlert && (
            <Alert
                open={!!sortAlert}
                setOpen={setSortAlert}
                level={Level.WARNING}
                text={"For at sortere efter 'Afstand til sælger' skal du slå lokalitetstjenester til i dine browserindstillinger"}
                duration={8000}
            />
        )}
        <span>Sortér:</span>
        <Button
          variant="Label"
          endElement={
            <ChevronDown
              style={{ marginTop: -2, color: 'var(--color-grey-3)' }}
              aria-hidden="true"
              focusable="false"
            />
          }
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {selectedOption?.label ?? 'Ukendt'}
        </Button>
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false} initialFocus={0}>
          <div
            ref={refs.setFloating}
            className={styles.popover}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {mergedOptions?.filter(Boolean).map((option, index) => (
              <button
                key={option!.key}
                {...getItemProps({
                  ref(node: HTMLButtonElement) {
                    listItemsRef.current[index] = node;
                  },
                  tabIndex: activeIndex === index ? 0 : -1,
                  onMouseEnter: () => {
                    if (allowHover && isOpen) {
                      setActiveIndex(index);
                    }
                  },
                  onClick: () => {
                    onChange?.(option!.sortBy!, option!.sortOrder!);
                    setIsOpen(false);
                    setSortAlert?.(false);
                  },
                  className: cx(styles.item, {
                    [styles.active]:
                      current?.sortBy === option!.sortBy &&
                      current?.sortOrder === option!.sortOrder,
                  }),
                  role: 'menuitem',
                })}
              >
                {option!.label}
              </button>
            ))}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};