import React from 'react';
import styles from './SearchAgent.module.scss';
import BellIcon from '@/icons/Bell';
import Button from '@/atoms/Button';
import { Dialog, DialogContent } from '@/atoms/Dialog';
import { trackPulseUIElementEvent } from '@smd/tracking';
import { useUser } from '@/hooks/useUser';
import { LoginNudge } from './LoginNudge';
import { SearchAgentContent } from './SearchAgentContent';
import { SmdBasSrpModelsSearchRequest } from 'types/api';

interface ISearchAgentProps {
  selectedFilters: SmdBasSrpModelsSearchRequest;
  position: 'SRP' | 'AllFilters';
}

export const SearchAgent = ({
  selectedFilters,
  position,
}: ISearchAgentProps) => {
  return (
    <>
      <SearchAgentDialog
        selectedFilters={selectedFilters}
        position={position}
      />
    </>
  );
};

interface IMakeDialogProps {
  selectedFilters: SmdBasSrpModelsSearchRequest;
  position: 'SRP' | 'AllFilters';
}

const SearchAgentDialog = ({ selectedFilters, position }: IMakeDialogProps) => {
  const { isAuthenticated } = useUser();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [nudgeOpen, setNudgeOpen] = React.useState(false);

  const handleSearchAgentOnClick = () => {
    if (!isAuthenticated) {
      setNudgeOpen(true);
      return;
    }
    trackPulseUIElementEvent({
      elementName: 'OpenSearchAgent',
      elementPosition: position,
    });
    setModalOpen(true);
  };

  return (
    <>
      <Button
        startElement={<BellIcon />}
        variant="Secondary"
        onClick={handleSearchAgentOnClick}
        className={styles.searchAgentBtn}
      >
        <span className={styles.searchAgentBtnText}>Opret søgeagent</span>
      </Button>
      <LoginNudge open={nudgeOpen} setNudgeOpen={setNudgeOpen} />
      <Dialog open={modalOpen} onOpenChange={setModalOpen}>
        <DialogContent
          transition={{
            initial: { transform: 'translateX(50px)' },
          }}
          className={styles.searchAgentDialog}
        >
          <SearchAgentContent selectedFilters={selectedFilters} />
        </DialogContent>
      </Dialog>
    </>
  );
};
