import classNames from 'classnames';
import styles from './CarouselIndicator.module.scss';

type Props = {
  length: number;
  index: number;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Indicator for e.g. carousel
 * @param length is the total amount of dots (only 5 will be shown at a time)
 * @param index is which zero-based index to highlight
 * @returns
 */
export const CarouselIndicator = ({
  length,
  index,
  className,
  ...rest
}: Props) => {
  if (length <= 1) return null; // Makes little sense to show indicators if only one or no elements exist

  return (
    <div className={classNames(styles.pagination, className)} {...rest}>
      <div
        className={styles['dot-container']}
        style={
          index > 2
            ? {
                transform: `translateX(calc(-${Math.min(
                  index - 2,
                  length - 5
                )} * calc(6px + var(--indicator-size, 6px))))`, // The 6px arbitrary value is probably margin
              }
            : {}
        }
      >
        {[...Array(length)].map((_, i) => (
          <span
            key={i}
            className={classNames(styles.dot, {
              [styles.active]: i === index,
            })}
          ></span>
        ))}
      </div>
    </div>
  );
};

CarouselIndicator.displayName = 'Indicator';

export default CarouselIndicator;
