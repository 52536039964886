import cx from 'classnames';
import styles from './IconButton.module.scss';

interface Props<T extends React.ElementType> {
  icon?: React.ReactNode;
  disabled?: boolean;
  as?: T;
}

export const IconButton = <T extends React.ElementType = 'button'>({
  as,
  icon,
  className,
  disabled = false,
  ...rest
}: Props<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof Props<T>>) => {
  const Component = as || 'button';
  return (
    <Component
      {...rest}
      className={cx(className, styles.iconButton, {
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
    >
      {icon}
    </Component>
  );
};
