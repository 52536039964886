import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './GallerySlider.module.scss';
import { IconButton } from '@/atoms/IconButton';
import ChevronRight from '@/icons/ChevronRight';
import cx from 'classnames';
import {
  PulseStandardEventType,
  trackPulseUIElementEvent,
} from '@smd/tracking';
import { useInView } from 'react-intersection-observer';

type Props = {
  children: React.ReactNode;
  overlay?: React.ReactNode | ((index: number) => React.ReactNode);
  className?: string;
  slideWidth: number;
  showControls: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const GallerySlider = React.memo(
  ({
    children,
    overlay,
    className,
    slideWidth,
    showControls,
    ...rest
  }: Props) => {
    const slideContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [isDisabledPrevBtn, disablePrevButton] = React.useState(false);
    const [isDisabledNextBtn, disableNextButton] = React.useState(false);
    const currentScrollPercentage = React.useRef<number>(0);
    const scrollTimer = React.useRef<NodeJS.Timeout | null>(null);

    const { ref: inViewRef, inView } = useInView({
      root: null,
      threshold: 0.5,
      triggerOnce: true,
    });

    const setRefs = React.useCallback(
      (node: HTMLDivElement) => {
        slideContainerRef.current = node;
        inViewRef(node);
      },
      [inViewRef]
    );

    const getScrolledPercentage = React.useCallback(() => {
      const slideContainer = slideContainerRef.current;
      if (!slideContainer) return 0;

      const totalWidth =
        slideContainer.scrollWidth - slideContainer.clientWidth;
      const windowScroll = slideContainer.scrollLeft;
      return (windowScroll / totalWidth) * 100;
    }, []);

    const getScrollPosition = React.useCallback(() => {
      const slideContainer = slideContainerRef.current;
      if (!slideContainer) return 'start';

      const totalWidth =
        slideContainer.scrollWidth - slideContainer.clientWidth;
      const windowScroll = slideContainer.scrollLeft;
      if (windowScroll === 0) {
        return 'start';
      } else if (windowScroll >= totalWidth) {
        return 'end';
      } else {
        return 'middle';
      }
    }, []);

    const updateButtons = React.useCallback(() => {
      const slideContainerPosition = getScrollPosition();
      if (slideContainerPosition === 'start') {
        disablePrevButton(true);
      } else {
        disablePrevButton(false);
      }

      if (slideContainerPosition === 'end') {
        disableNextButton(true);
      } else {
        disableNextButton(false);
      }
    }, [getScrollPosition]);

    const scrollFinished = React.useCallback(() => {
      if (getScrollPosition() === 'start') {
        // if scroll position is at the start, we don't track
        return;
      }
      const scrolledPercentage = getScrolledPercentage();
      if (scrolledPercentage > currentScrollPercentage.current) {
        // track only if the user has scrolled forward and more than the previous percentage scrolled;
        // this tracking is for measuring scroll progress in the gallery and only gets called until the user reaches 100% scroll progress (per SSR loaded page)
        trackPulseUIElementEvent({
          eventType: PulseStandardEventType.View,
          elementName: 'RelatedListingsScrollPercentage',
          eventDescription: `${Math.round(scrolledPercentage)}%`,
        });
        currentScrollPercentage.current = scrolledPercentage;
      }
    }, [getScrollPosition, getScrolledPercentage]);

    const onScroll = React.useCallback(() => {
      // make sure to update the buttons, if the content/children have changed
      updateButtons();
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }

      scrollTimer.current = setTimeout(scrollFinished, 500); // debounce the pulse tracking for percentage scrolled
    }, [updateButtons, scrollFinished]);

    React.useEffect(() => {
      if (inView) {
        trackPulseUIElementEvent({
          elementName: 'RelatedListingsGallery',
          eventType: PulseStandardEventType.View,
        });
      }
    }, [inView]);

    React.useEffect(() => {
      if (!slideContainerRef.current) return;
      const slideContainer = slideContainerRef.current;
      // scroll slider back to initial scroll position, if its content/children have changed
      slideContainer.scrollTo(0, 0);
      // make sure to update the buttons, if the content/children have changed
      updateButtons();

      // make sure to update the buttons, if the user scrolls the slider
      slideContainer.addEventListener('scroll', onScroll, {
        passive: true,
      });
      return () => {
        slideContainer.removeEventListener('scroll', onScroll);
        scrollTimer?.current && clearTimeout(scrollTimer.current);
      };
    }, [children, updateButtons, onScroll]);

    const handlePrev = () => {
      slideContainerRef.current?.scrollBy({
        behavior: 'smooth',
        left: -slideWidth,
      });
      updateButtons();
    };

    const handleNext = () => {
      slideContainerRef.current?.scrollBy({
        behavior: 'smooth',
        left: slideWidth,
      });
      updateButtons();
    };

    return (
      <div className={classNames(styles.gallerySlider, className)} {...rest}>
        <div className={styles.header}>
          <div className={styles.info}>
            <span className={styles.infoLabel}>Annoncering</span>
            <span className={styles.infoText}>
              Andre biler der kunne have din interesse
            </span>
          </div>

          {showControls && (
            <div>
              <IconButton
                icon={<ChevronRight style={{ transform: 'rotateZ(180deg)' }} />}
                className={cx(styles.prev, {
                  [styles.disabled]: isDisabledPrevBtn,
                })}
                disabled={isDisabledPrevBtn}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handlePrev();
                }}
              ></IconButton>
              <IconButton
                icon={<ChevronRight />}
                className={cx(styles.next, {
                  [styles.disabled]: isDisabledNextBtn,
                })}
                disabled={isDisabledNextBtn}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleNext();
                }}
              ></IconButton>
            </div>
          )}
        </div>
        <div
          className={styles['slide-container']}
          ref={setRefs}
          tabIndex={0}
          style={{ ['--slide-min-width']: `${slideWidth}px` } as any}
        >
          {React.Children.count(children) > 0 &&
            React.Children.map(children, (child) => (
              <div className={cx(styles.slide, 'slider-slide')}>{child}</div>
            ))}
        </div>
      </div>
    );
  }
);

GallerySlider.displayName = 'GallerySlider';
