import cx from 'classnames';
import { FilterIcon } from '@/icons/FilterIcon';
import { SearchIcon } from '@/icons/SearchIcon';
import Badge from '@/atoms/Badge';
import Button from '@/atoms/Button';
import TextField from '@/atoms/TextField';
import styles from './SearchActions.module.scss';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogHeading,
  DialogTrigger,
} from '@/atoms/Dialog';
import { AllFilters } from './filters/AllFilters';

type Props = {
  children: React.ReactNode;
};

export const SearchActions = ({ children }: Props) => {
  return (
    <section className={styles.actions}>
      {/* <div style={{ flex: '1 1 auto' }}>
        <TextField
          placeholder="Søg på bilmærke, model, type..."
          endElement={<Button endElement={<SearchIcon />}>29.926 biler</Button>}
          fullWidth
          wrapperProps={{ style: { maxWidth: 430 } }}
        />
      </div> */}

      {/* <Dialog>
        <DialogTrigger>
          <Button variant="Secondary">Opret søgeagent</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeading>Opret søgeagent</DialogHeading>
          <DialogBody>Create search agent options</DialogBody>
          <DialogActions>
            <DialogClose>
              <Button variant="Label">Luk</Button>
            </DialogClose>
            <DialogClose>
              <Button>Opret</Button>
            </DialogClose>
          </DialogActions>
        </DialogContent>
      </Dialog> */}
      {children}
    </section>
  );
};
