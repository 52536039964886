import Image, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';
import fallbackImg from '../../public/missing.svg';

interface ImageWithFallbackProps extends Omit<ImageProps, 'src'> {
  fallback?: ImageProps['src'] | null;
  src?: ImageProps['src'] | null;
}

export const ImageWithFallback = ({
  fallback = fallbackImg,
  alt,
  src = fallbackImg,
  ...props
}: ImageWithFallbackProps) => {
  const [error, setError] = useState<React.SyntheticEvent<
    HTMLImageElement,
    Event
  > | null>(null);

  useEffect(() => {
    setError(null);
  }, [src]);

  if (src === null && fallback === null) return null;
  if (fallback === null && !!error) return null;

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <Image
      alt={alt}
      onError={setError}
      src={(error || src === null ? fallback! : src) as string}
      unoptimized={!!error}
      {...props}
    />
  );
};
