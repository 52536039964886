import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './Carousel.module.scss';
import { ImageWithFallback } from '@/atoms/ImageWithFallback';
import { IconButton } from '@/atoms/IconButton';
import ChevronRight from '@/icons/ChevronRight';
import { clamp } from '@/utils';
import { useInView } from 'react-intersection-observer';

type CarouselProps = {
  children: React.ReactNode;
  overlay?: React.ReactNode | ((index: number) => React.ReactNode);
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Carousel = ({
  children,
  overlay,
  className,
  ...rest
}: CarouselProps) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = React.useState(0);

  React.useEffect(() => {
    if (!carouselRef.current) return;

    const element = carouselRef.current;
    const noOfChildren = React.Children.count(children);

    const scrollListener = () => {
      const windowScroll = element.scrollLeft;
      const totalWidth = element.scrollWidth - element.clientWidth;

      if (windowScroll === 0) return setScroll(0);
      if (windowScroll >= totalWidth) return setScroll(noOfChildren - 1);
      return setScroll(Math.floor((windowScroll / totalWidth) * noOfChildren));
    };
    element.addEventListener('scroll', scrollListener, { passive: true });
    return () => element.removeEventListener('scroll', scrollListener);
  }, [children]);

  const scrollToIndex = (index: number) => {
    //const slideIndex = clamp(index, 0, React.Children.count(children)); ?
    const slideIndex =
      index >= 0
        ? index % React.Children.count(children)
        : (index + React.Children.count(children)) %
          React.Children.count(children);
    carouselRef.current?.querySelectorAll('div')[slideIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  };

  return (
    <div
      className={classNames(
        styles.carousel,
        { [styles.inview]: inView },
        className
      )}
      {...rest}
      ref={ref}
    >
      <div className={styles.overlay}>
        {typeof overlay === 'function' ? overlay(scroll) : overlay}
        {React.Children.count(children) > 1 && (
          <>
            <IconButton
              icon={<ChevronRight />}
              className={styles.next}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                scrollToIndex(scroll + 1);
              }}
            ></IconButton>
            <IconButton
              icon={<ChevronRight style={{ transform: 'rotateZ(180deg)' }} />}
              className={styles.prev}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                scrollToIndex(scroll - 1);
              }}
            ></IconButton>
          </>
        )}
      </div>
      <div className={styles['slide-container']} ref={carouselRef} tabIndex={0}>
        {React.Children.map(children, (child) => (
          <div className={styles.slide}>{child}</div> // TODO: Consider not wrapping, but instead attaching a class to the child (it could be string though...)
        ))}
      </div>
    </div>
  );
};

export default Carousel;
