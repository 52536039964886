import { formatNumber } from '@/utils';
import styles from './Pagination.module.scss';
import { IconButton } from '@/atoms/IconButton';
import ChevronRight from '@/icons/ChevronRight';
import { SmdBasSrpModelsPagination } from 'types/api';

type Props = {
  page?: number;
  maxPages?: number;
} & SmdBasSrpModelsPagination;

export const Pagination = ({ previous, next, page, maxPages }: Props) => {
  return (
    <div className={styles.pagination}>
      <IconButton
        as="a"
        data-e2e="pagination-prev"
        href={previous?.link?.split('https://www.bilbasen.dk')[1]}
        title="Forrige side"
        icon={<ChevronRight style={{ transform: 'rotateZ(180deg)' }} />}
        disabled={!previous}
      />
      <div>
        Side{' '}
        <span data-e2e="pagination-current">
          {typeof page !== 'undefined' ? page : '?'}
        </span>{' '}
        af{' '}
        <span data-e2e="pagination-total">
          {typeof maxPages !== 'undefined'
            ? formatNumber(maxPages)
            : 'ukendt antal'}
        </span>
      </div>
      <IconButton
        as="a"
        data-e2e="pagination-next"
        href={next?.link?.split('https://www.bilbasen.dk')[1]}
        title="Næste side"
        icon={<ChevronRight />}
        disabled={!next}
      />
    </div>
  );
};
