import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  useHover,
  useDismiss,
  useRole,
  useInteractions,
  useTransitionStyles,
  FloatingPortal,
  Placement,
  safePolygon,
  useDelayGroupContext,
  useDelayGroup,
} from '@floating-ui/react';
import React, { useId } from 'react';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  content?: React.ReactNode;
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  placement?: Placement;
  clickable?: boolean;
  duration?: number;
  delay?: number;
};

export const Tooltip = ({
  content,
  children,
  placement = 'top',
  clickable = false,
  duration = 250,
  delay: propDelay,
}: TooltipProps) => {
  const [open, setOpen] = React.useState(false);
  const { delay } = useDelayGroupContext();
  const id = useId();

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), shift()],
  });

  const hover = useHover(context, {
    move: false,
    mouseOnly: true,
    delay: propDelay ?? delay,
    handleClose: clickable ? safePolygon() : undefined,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const interactions = useInteractions([hover, dismiss, role]);

  useDelayGroup(context, { id });

  const { isMounted, styles: ttStyles } = useTransitionStyles(context, {
    duration,
  });

  if (!React.isValidElement(children)) return null;
  if (typeof content === 'undefined') return children;

  return (
    <>
      {React.cloneElement(
        children,
        interactions.getReferenceProps({
          ref: refs.setReference,
        })
      )}
      {isMounted && (
        <FloatingPortal>
          <div
            className={styles.tooltip}
            ref={refs.setFloating}
            style={{
              // Positioning styles
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              ...ttStyles,
            }}
            {...interactions.getFloatingProps()}
          >
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
