import cx from 'classnames';
import styles from './NoResultsMessage.module.scss';

export const NoResultsMessage = () => {
  return (
    <div className={cx('spanAllColumns', styles.noresults)}>
      Der var desværre ingen resultater der matcher din søgning - prøv mindre
      specifikke søgekriterier.
    </div>
  );
};
