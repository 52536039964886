import React from 'react';
import {
  SmdBasSeoContractUrlAndLinkText,
  SmdBasSrpWebApiModelsSeoData,
} from 'types/api';
import styles from './PageSeo.module.scss';
import Button from '@/atoms/Button';
import SEOFAQ from "@/atoms/SEOFAQ";

type Props = {
  data: SmdBasSrpWebApiModelsSeoData;
};

const cleanSeoText = (text: string) =>
  text.replace(/<br\W*\/?>/g, ' ').replace('<h3', '<h4');

const ExpandableList = ({
  items,
  maxItems = 10,
}: {
  items?: SmdBasSeoContractUrlAndLinkText[];
  maxItems?: number;
}) => {
  const [showAll, setShowAll] = React.useState(false);
  if ((items?.length ?? 0) === 0) return null;

  const toShow = showAll ? items : items?.slice(0, maxItems);

  const toggleShow = () => setShowAll((prev) => !prev);

  return (
    <>
      <ul className={styles.related}>
        {toShow
          ?.filter((item) => typeof item.absoluteUrl === 'string')
          .map((item, index) => (
            <li key={index}>
              <a href={item.absoluteUrl!}>{item.linkText}</a>
            </li>
          ))}
      </ul>

      {(items?.length ?? 0) > maxItems && (
        <Button
          onClick={toggleShow}
          variant="Label"
          className={styles.showMore}
        >
          {showAll ? 'Se færre' : 'Se alle'}
        </Button>
      )}
    </>
  );
};

export const SeoBottomPage = ({ data }: Props) => {
  const silos =
    data.seoSiloParagraphs?.filter(
      (seoSilo) => seoSilo.links?.length ?? 0 > 0
    ) ?? [];

  return (
    <div className={styles.seoContent}>
      {(data.popularSearches?.length ?? 0) > 0 && (
        <div>
          <h4>Relaterede søgninger</h4>
          <ExpandableList
            items={data.popularSearches ?? undefined}
            maxItems={20}
          />
        </div>
      )}
      {!!data.seoText?.text1 ? (
        <div>
          <h4>{data.seoText?.make}</h4>
          <div
            className={styles.seoText}
            dangerouslySetInnerHTML={{
              __html: cleanSeoText(data.seoText?.text1 ?? ''),
            }}
          />
        </div>
      ) : null}

      {silos.map((silo) => (
        <div key={silo.headLine}>
          <h4>{silo.headLine}</h4>
          <ExpandableList items={silo.links ?? undefined} />
        </div>
      ))}

      {!!data.seoText?.text2 ? (
        <div
          className={styles.seoText}
          dangerouslySetInnerHTML={{
            __html: cleanSeoText(data.seoText?.text2 ?? ''),
          }}
        />
      ) : null}
      {!!data.seoText?.text3 ? (
        <div
          className={styles.seoText}
          dangerouslySetInnerHTML={{
            __html: cleanSeoText(data.seoText?.text3 ?? ''),
          }}
        />
      ) : null}
      {/*<SEOFAQ faqs={data.faqs} />*/}
      {/*{data.faqs?.faqList?.map((faq) => (*/}
      {/*  <div*/}
      {/*    key={faq.question}*/}
      {/*    className={styles.seoText}*/}
      {/*    dangerouslySetInnerHTML={{*/}
      {/*      __html: cleanSeoText(`<h4>${faq.question}</h4>${faq.answer}`),*/}
      {/*    }}*/}
      {/*  />*/}
      {/*))}*/}
    </div>
  );
};
