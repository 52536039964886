import { useQuery } from '@tanstack/react-query';
import { getRelatedListings } from 'api/search';
import { SmdBasSrpModelsSearchRequest } from 'types/api';
import cx from 'classnames';
import styles from './RelatedListings.module.scss';
import { RelatedListing } from '@/molecules/RelatedListing';
import { GallerySlider } from '@/molecules/GallerySlider';
import { useMeasure } from 'react-use';
import React from 'react';
import { useInView } from 'react-intersection-observer';

type Props = {
  backendSearchRequest: SmdBasSrpModelsSearchRequest; // use the backend search request since that is already debounced
};

const MIN_SLIDE_WIDTH = 216 + 2 * 8; // slide width = card width (216px) + left shadow (8px) + right shadow (8px)

export const RelatedListings = React.memo(({ backendSearchRequest }: Props) => {
  const { ref: inViewRef, inView } = useInView({
    root: null,
    rootMargin: '100px 0px',
    threshold: 0,
    triggerOnce: true,
  });

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const { data } = useQuery({
    queryKey: ['related-listings', backendSearchRequest],
    queryFn: async () => getRelatedListings({ ...backendSearchRequest }),
    staleTime: 20000,
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true,
    enabled: inView,
  });

  // Calculate how many slides to show
  const slidesToShow = React.useMemo(() => {
    const maxSlides = Math.floor(width / MIN_SLIDE_WIDTH);
    const widthOfSlides = maxSlides * MIN_SLIDE_WIDTH;
    return width - widthOfSlides > 0 ? maxSlides : maxSlides - 1;
  }, [width]);

  // Calculate the width of each slide
  const slideWidth = React.useMemo(() => {
    if (
      (slidesToShow === 2 && width / slidesToShow < MIN_SLIDE_WIDTH) ||
      slidesToShow === 1
    ) {
      return MIN_SLIDE_WIDTH;
    }
    return width / slidesToShow;
  }, [width, slidesToShow]);

  return (
    <section ref={inViewRef}>
      {data && data.length > 0 && (
        <div className={styles.gallery}>
          <h2>Mere fra vores sælgere</h2>
          <div className={cx(styles.listings)} ref={ref}>
            <GallerySlider
              slideWidth={slideWidth || MIN_SLIDE_WIDTH}
              showControls={data.length > slidesToShow}
            >
              {data.map((relatedListing, index) => {
                return (
                  <RelatedListing
                    key={index}
                    data={relatedListing}
                    rank={index + 1}
                  />
                );
              })}
            </GallerySlider>
          </div>
        </div>
      )}
    </section>
  );
});

RelatedListings.displayName = 'RelatedListings';
