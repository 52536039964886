export const SmallCloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={17}
    width={16}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="m11 5.5-6 6M5 5.5l6 6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SmallCloseIcon.displayName = 'SmallCloseIcon';
export default SmallCloseIcon;
